import React from 'react';
import { ThreeDots, Watch } from 'react-loader-spinner';
import { Container } from './styles';

/**
 * @Types:
 * "Audio" | "BallTriangle" | "Bars" | "Circles" | "Grid" | "Hearts" | "Rings" | "Puff" | "Rings" | "TailSpin" | "ThreeDots" | "Watch" | "RevolvingDot" | "Triangle" |  *  "Plane" | "MutatingDots" | "None" | "NotSpecified"
 * */
interface LoaderSpinnerProps {
  isSmall?: boolean;
}

const LoaderSpinner = ({ isSmall }: LoaderSpinnerProps) => {
  const size = isSmall ? 40 : 80;

  return (
    <Container isSmall={isSmall}>
      {isSmall ? (<ThreeDots color="#31B86F" height={size} width={size} />) : (
        <Watch color="#31B86F" height={size} width={size} />
      )}
      {!isSmall && <p>Carregando...</p>}
    </Container>
  );
};

export default LoaderSpinner;
