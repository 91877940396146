import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Tabs } from 'antd';
import { AxiosError } from 'axios';

import { ForgotPassword } from '../../../components/ForgotPassword';
import { ResetPassword } from '../../../components/ResetPassword';
import { SignIn } from '../../../components/SignIn';
import { SignUp } from '../../../components/SignUp';

import { FormLayout } from '../../../utils/Styles';
import { Container, Content } from './styles';

import api from '../../../services/api';
import { PREFIX_LOCALSTORAGE } from '../../../utils/Constants';
import { PanelResult } from '../../../components/PanelResult';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../components/Notification';

const { TabPane } = Tabs;

enum Tab {
  SIGN_IN = 'Entrar',
  SIGN_UP = 'Cadastre-se',
  FORGOT_PASSWORD = 'Esqueceu a senha?',
}

export const SessionPage = () => {
  const [currentTab, setCurrentTab] = useState<Tab>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isResetPassword, setResetPassword] = useState<boolean>(false);
  const [showPasswordChangeResult, setShowPasswordChangeResult] = useState<boolean>(false);
  const [resetToken, setResetToken] = useState<string>('');

  const { hash: sessionTab } = useLocation();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    let tab = Tab.SIGN_IN;

    if (sessionTab === '#signup') {
      tab = Tab.SIGN_UP;
    } else if (sessionTab === '#forgotpassword') {
      tab = Tab.FORGOT_PASSWORD;
    }

    setCurrentTab(tab);
  }, [sessionTab]);

  const showErrorMessage = (message: string) => {
    NotificationDispatcher({
      message,
      type: NotificationType.ERROR,
    });
  };

  const onTabChanged = (key: string) => setCurrentTab(key as Tab);

  const doSignIn = async (formData: any) => {
    try {
      const response = await api.post('api/auth/login', formData);
      const data = response.data;
      if (data.success) {
        localStorage.setItem(`${PREFIX_LOCALSTORAGE}token`, data.token);
        navigate('/sysadmin/');
      } else {
        showErrorMessage(data);
      }
    } catch (ex) {
      const error = ex as AxiosError;

      if (error.response) {
        switch (error.response.status) {
          case 403:
            const isUserInactive = error.response.data.error === 'User is not active';
            const message = isUserInactive ? 'Usuário inativo' : 'A sua empresa está inativa. Procure o administrador do sistema.';
            
            showErrorMessage(message);
            break;
          default:
            showErrorMessage('Email e/ou senha inválidos');
            break;
        }
      } else {
        showErrorMessage(error.message);
      }
    } finally {
      setFetching(false);
    }
  };

  const doSignUp = async (formData: any) => {
    try {
      const response = await api.post('api/auth/register', formData);
      const data = response.data;
      if (data.success) {
        localStorage.setItem(`${PREFIX_LOCALSTORAGE}token`, data.token);
        navigate('/sysadmin/');
      } else {
        showErrorMessage(data);
      }
    } catch (ex) {
      const error = ex as AxiosError;

      if (error.response) {
        switch (error.response.data.error) {
          case 'Invalid Access Code':
            showErrorMessage('Código de confirmação inválido.');
            break;
          case 'Your company is not active':
            showErrorMessage(
              'A sua empresa está inativa. Procure o administrador do sistema.'
            );
            break;
          case 'Duplicate field value entered':
            showErrorMessage('Email já cadastrado.');
            break;
          default:
            showErrorMessage(
              'Error ao tentar efetuar o cadastro. Por favor, verifique se o email é válido ou se o código está correto.'
            );
            break;
        }
      } else {
        showErrorMessage(error.message);
      }
    } finally {
      setFetching(false);
    }
  };

  const requestNewTokenForgotPassword = async (formData: any) => {
    try {
      const response = await api.post('api/auth/forgotpassword', formData);

      const data = response.data;
      if (data.success) {
        setResetToken(data.data.resetToken);
        setResetPassword(true);
      } else {
        showErrorMessage(data);
      }
    } catch (ex) {
      const error = ex as AxiosError;

      if (error.response) {
        switch (error.response.status) {
          case 404:
            showErrorMessage('Email inválido');
            break;
          case 400:
            showErrorMessage('Código do estabelecimento inválido');
            break;
          default:
            showErrorMessage(
              'Estabelecimento temporariamente inativo. Procure a administração do QRcode Preferido.'
            );
        }
      } else {
        showErrorMessage(error.message);
      }
    } finally {
      setFetching(false);
    }
  };

  const setNewPassword = async (formData: any) => {
    try {
      const response = await api.put(
        `api/auth/resetpassword/${resetToken}`,
        formData
      );

      const data = response.data;
      if (data.success) {
        setShowPasswordChangeResult(true);
      } else {
        showErrorMessage(data);
      }
    } catch (ex) {
      const error = ex as AxiosError;
      showErrorMessage(error.message);
    } finally {
      setFetching(false);
    }
  };

  const onFinish = (values: any) => {
    setFetching(true);

    switch (currentTab) {
      case Tab.SIGN_IN:
        doSignIn(values);
        break;
      case Tab.SIGN_UP:
        doSignUp(values);
        break;
      case Tab.FORGOT_PASSWORD:
        if (isResetPassword) {
          setNewPassword(values);
        } else {
          requestNewTokenForgotPassword(values);
        }
        break;
    }

    form.resetFields();
  };

  return (
    <Container>
      <Content>
        <img alt="Logo" src={require('../../../assets/images/logo-no-bg.png')} />

        <Form
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          {...FormLayout}
          style={{ marginTop: '30px' }}
        >
          <Tabs activeKey={currentTab?.toString()} onChange={onTabChanged}>
            <TabPane tab={Tab.SIGN_IN} key={Tab.SIGN_IN}>
              <SignIn isFetching={isFetching} isRequired={currentTab === Tab.SIGN_IN} />
            </TabPane>

            <TabPane tab={Tab.SIGN_UP} key={Tab.SIGN_UP}>
              <SignUp isFetching={isFetching} isRequired={currentTab === Tab.SIGN_UP} />
            </TabPane>

            <TabPane tab={Tab.FORGOT_PASSWORD} key={Tab.FORGOT_PASSWORD}>
              {showPasswordChangeResult ? (
                <PanelResult
                  title="Nova senha criada com sucesso!"
                  subtitle="Agora você pode efetuar o seu login e usufruir do sistema."
                  extra={
                    <Button
                      type="primary"
                      onClick={() => {
                        setCurrentTab(Tab.SIGN_IN);
                        setShowPasswordChangeResult(false);
                        setResetPassword(false);
                        setResetToken('');
                      }}
                    >
                      Logar!
                    </Button>
                  }
                />
              ) : isResetPassword ? (
                <ResetPassword isFetching={isFetching} />
              ) : (
                <ForgotPassword isFetching={isFetching} isRequired={currentTab === Tab.FORGOT_PASSWORD} />
              )}
            </TabPane>
          </Tabs>
        </Form>
      </Content>
    </Container>
  );
};
