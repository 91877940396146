import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';

import { Button, Form, Input, Space } from 'antd';

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { FormRadioGroup } from '../../../../../components/FormRadioGroup';
import TimePickers from '../../../../../components/time-picker';
import WeekdaysList from '../../../../../components/WeekdaysList';

import { FeaturesMenuOptions } from '../../../../../models/DataResponse';
import api from '../../../../../services/api';

import { PageTitle } from './styles';
import {
  HourErrorMessage,
  ScheduleContainer,
  ScheduleContent,
} from '../../../../../common-styles';

interface NewMenuOptionProps {
  featureId: string | undefined;
  selectedFeaturesMenuOptions?: FeaturesMenuOptions;
  handleMenuOptionsChange(option: FeaturesMenuOptions, isUpdate: boolean): void;
  handleShowMenuOptionsPage(isShow: boolean): void;
}

export const NewMenuOption = ({
  featureId,
  selectedFeaturesMenuOptions,
  handleMenuOptionsChange,
  handleShowMenuOptionsPage,
}: NewMenuOptionProps) => {
  const [formData, setFormData] = useState<FeaturesMenuOptions>(
    {} as FeaturesMenuOptions
  );
  const [isEnabled, setEnabled] = useState<boolean>(true);
  const [isEveryday, setIsEveryday] = useState<boolean>(true);
  const [isAllDay, setAllDay] = useState<boolean>(true);
  const [isSellViaWhatsappEnabled, setSellViaWhatsappEnabled] =
    useState<boolean>(true);
  const [startWeekdays, setStartWeekdays] = useState<number[]>([]);
  const [weekdays, setWeekdays] = useState<number[]>([]);
  const [hoursErrorMessage, setHoursErrorMessage] = useState<string>('');

  useEffect(() => {
    if (selectedFeaturesMenuOptions) {
      setFormData(selectedFeaturesMenuOptions);
      setAllDay(selectedFeaturesMenuOptions.allDay);
      setSellViaWhatsappEnabled(
        selectedFeaturesMenuOptions.isSellViaWhatsappEnabled
      );
      setIsEveryday(selectedFeaturesMenuOptions.weekdays === 'everyday');
      setStartWeekdays(
        selectedFeaturesMenuOptions.weekdays
          .split('|')
          .filter((day) => day)
          .map((day) => Number(day))
      );
      setEnabled(selectedFeaturesMenuOptions.isEnabled);
    }
  }, [selectedFeaturesMenuOptions]);

  const handleAddNewMenuOption = async (event: FormEvent) => {
    event.preventDefault();

    if (!formData.order) {
      formData.order = 1;
    }

    if (
      formData.endHour &&
      formData.startHour &&
      formData.endHour.localeCompare(formData.startHour) < 0
    ) {
      setHoursErrorMessage(
        'A hora final não pode ser inferior a hora inicial.'
      );
      return;
    }

    const body = {
      featureId: featureId || undefined,
      name: formData.name,
      icon: formData.icon ? formData.icon : '',
      order: formData.order,
      weekdays: getWeekdays(),
      startHour: formData.startHour ? formData.startHour : '',
      endHour: formData.endHour ? formData.endHour : '',
      allDay: formData.allDay,
      isEnabled,
      isSellViaWhatsappEnabled,
    };

    if (selectedFeaturesMenuOptions) {
      try {
        delete body.featureId;

        const response = await api.put(
          `/api/features/menuoption/${selectedFeaturesMenuOptions._id}`,
          body
        );
        handleMenuOptionsChange(
          response.data.data as FeaturesMenuOptions,
          true
        );
        handleShowMenuOptionsPage(false);
      } catch (e) {
        console.error('Fail updating MenuOption', e);
      }
    } else {
      try {
        const response = await api.post('/api/features/menuoption/', body);
        handleMenuOptionsChange(
          response.data.data as FeaturesMenuOptions,
          false
        );
        handleShowMenuOptionsPage(false);
      } catch (e) {
        console.error('Fail adding MenuOption', e);
      }
    }
  };

  const getWeekdays = () => {
    if (isEveryday || weekdays.length === 0) {
      return 'everyday';
    }

    return weekdays.join('|');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <Form layout="vertical" initialValues={selectedFeaturesMenuOptions}>
      <PageTitle>
        {selectedFeaturesMenuOptions ? 'Editar Aba' : 'Nova Aba'}
      </PageTitle>
      <FormRadioGroup
        label="Disponibilidade"
        propertyId="isEnabled"
        defaultValue={isEnabled}
        handleOnChange={(checked) => setEnabled(checked)}
      />
      <Form.Item
        label={<label className="label-input-field">Nome</label>}
        name="name"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <Input id="name" type="text" onChange={handleInputChange} style={{ height: '40px' }} />
      </Form.Item>

      <Form.Item
        name="order"
        label={<label className="label-input-field">Ordem</label>}
      >
        <Input id="order" type="text" onChange={handleInputChange} style={{ height: '40px' }} />
      </Form.Item>

      <Form.Item
        name="icon"
        label={<label className="label-input-field">Ícone</label>}
      >
        <Input id="icon" type="text" onChange={handleInputChange} style={{ height: '40px' }} />
      </Form.Item>

      <Space direction="vertical">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isEveryday}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setIsEveryday(checked);
                }}
                name="isEveryday"
              />
            }
            label="Exibir todos os dias"
          />
        </FormGroup>

        {!isEveryday && (
          <div style={{ marginLeft: '16px' }}>
            <WeekdaysList
              selectedWeekdays={startWeekdays}
              handleSelectedWeekdays={(days) => setWeekdays(days)}
            />
          </div>
        )}

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllDay}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setFormData({
                    ...formData,
                    allDay: checked,
                  });
                  setAllDay(checked);
                }}
                name="allDay"
              />
            }
            label="Dia inteiro"
          />
        </FormGroup>

        {!isAllDay && (
          <ScheduleContainer>
            <ScheduleContent>
              <TimePickers
                id={'startHour'}
                label="Início"
                defaultValue={formData.startHour ? formData.startHour : ''}
                handleInputChange={handleInputChange}
              />
              <TimePickers
                id={'endHour'}
                label="Fim"
                defaultValue={formData.endHour ? formData.endHour : ''}
                handleInputChange={handleInputChange}
              />
            </ScheduleContent>

            <HourErrorMessage>{hoursErrorMessage}</HourErrorMessage>
          </ScheduleContainer>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={isSellViaWhatsappEnabled}
              onChange={(e) => setSellViaWhatsappEnabled(e.target.checked)}
              name="isSellViaWhatsappEnabledForAllTabs"
            />
          }
          label="Venda por WhatsApp"
        />

        <Space>
          <Button type="primary" onClick={handleAddNewMenuOption}>
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>

          <Button danger onClick={() => handleShowMenuOptionsPage(false)}>
            Cancelar
          </Button>
        </Space>
      </Space>
    </Form>
  );
};
