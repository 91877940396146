import React, { useState } from 'react';
import { Button, Form, Switch } from 'antd';

import {
  OpeningHours,
} from '../../../../../../models/DataResponse';
import {
  CompanyFeatures,
} from '../../../../../../models/CompanyFeatures';
import { MIDNIGHT } from '../../../../../../utils/Constants';

import WorkSchedulePageDetail from './PageDetails';
import WorkScheduleList from './List';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../../components/Notification';

import { EmptyStateMessage } from '../../../styles';
import { UpdateCompanyFeaturesService } from '../../../../../../services/UpdateCompanyFeaturesService';

export interface WorkHours {
  startHour: string;
  endHour: string;
}

interface WorkScheduleProps {
  companyFeatures: CompanyFeatures;
  isSellsEnabled: boolean;
};

export const WorkSchedule = ({ isSellsEnabled, companyFeatures }: WorkScheduleProps) => {
  const [isShowingPage, setShowingPage] = useState(false);
  const [isEstablishmentOpen, setEstablishmentOpen] = useState(companyFeatures.isEstablishmentOpen);
  const [selectedOpenHours, setSelectedOpenHours] = useState<OpeningHours | null>(null);
  const [selectedOpeningHourList, setSelectedOpeningHourList] = useState<OpeningHours[]>(companyFeatures.openingHours);
  const [startWeekdays, setStartWeekdays] = useState<number[]>([]);

  const [workHour, setWorkHour] = useState<WorkHours>({
    startHour: MIDNIGHT,
    endHour: MIDNIGHT,
  } as WorkHours);

  function handleAddOrEditAction(open: OpeningHours | null) {
    if (open) {
      setSelectedOpenHours(open);
      setStartWeekdays(open.weekdays);
      setWorkHour({
        startHour: open.startHour,
        endHour: open.endHour,
      });
    } else {
      setSelectedOpenHours(null);
      setStartWeekdays([]);
      setWorkHour({ startHour: MIDNIGHT, endHour: MIDNIGHT } as WorkHours);
    }
    setShowingPage(true);
  }

  async function handleDeleteAction(id?: string) {
    if (!id) return;

    companyFeatures.openingHours = selectedOpeningHourList.filter(
      (item) => item._id !== id
    );

    const response = await UpdateCompanyFeaturesService.update(companyFeatures);
    if (response) {
      setSelectedOpeningHourList(response.openingHours);
      NotificationDispatcher({
        message: 'Horário removido com sucesso.',
      });
    } else {
      NotificationDispatcher({
        message: 'Não foi possível efetuar a operação.',
        type: NotificationType.ERROR,
      });
    }
  }

  async function handleEstablishmentClosing(checkedValue: boolean) {
    companyFeatures.isEstablishmentOpen = checkedValue;

    const response = await UpdateCompanyFeaturesService.update(companyFeatures);
    if (response) {
      setEstablishmentOpen(checkedValue);
      const message = checkedValue
        ? 'Estabelecimento aberto para vendas'
        : 'Estabelecimento encerrado para vendas';

      NotificationDispatcher({
        message,
      });
    } else {
      NotificationDispatcher({
        message: 'Não foi possível efetuar a operação.',
        type: NotificationType.ERROR,
      });
    }
  }

  async function handlePageResult(features: CompanyFeatures | null) {
    if (features === null) {
      setShowingPage(false);
      return;
    }

    const response = await UpdateCompanyFeaturesService.update(features);
    if (response) {
      setSelectedOpeningHourList(response.openingHours);
      setShowingPage(false);
      NotificationDispatcher({
        message: 'Operação realizada com sucesso.',
      });
    } else {
      NotificationDispatcher({
        message: 'Não foi possível efetuar a operação.',
        type: NotificationType.ERROR,
      });
    }
  }

  return (
    <>
      <WorkSchedulePageDetail
        isOpen={isShowingPage}
        selectedOpenHours={selectedOpenHours}
        startWorkHour={workHour}
        startWeekdays={startWeekdays}
        companyFeatures={companyFeatures}
        pageCallbackResult={handlePageResult}
      />

      {isSellsEnabled && (
        <Form layout="vertical">
          <Form.Item
            help="Esta opção permite encerrar o estabelecimento, independentemente do horário previamente configurado. Assim, o cliente poderá ver os produtos, mas não conseguirá efetuar compras."
            label={
              <label className="label-input-field">
                Fechar fora do horário programado
              </label>
            }
            name="isEstablishmentOpen"
          >
            <Switch
              id="isEstablishmentOpen"
              key="isEstablishmentOpen"
              defaultChecked={isEstablishmentOpen}
              checkedChildren="Aberto"
              unCheckedChildren="Fechado"
              onChange={handleEstablishmentClosing}
            />
          </Form.Item>
        </Form>
      )}

      {selectedOpeningHourList.length === 0 ? (
        <EmptyStateMessage>
          <strong>Nenhum horário cadastrado</strong>
        </EmptyStateMessage>
      ) : (
        <WorkScheduleList
          isSellsEnabled={isSellsEnabled}
          items={selectedOpeningHourList}
          deleteAction={handleDeleteAction}
          editAction={handleAddOrEditAction}
        />
      )}

      <div>
        <Button type="primary" onClick={() => handleAddOrEditAction(null)}>
          <i
            className="fa fa-plus-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Adicionar os horários
        </Button>
      </div>
    </>
  );
};
