import React, {
  ChangeEvent,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import parse from 'html-react-parser';
import InputMask from 'react-input-mask';
import PhoneInput from 'react-phone-number-input';
import isNil from 'lodash/isNil';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import { Checkbox, DatePicker, TimePicker } from 'antd';

import BoxWithLabel from '../../../../../components/BoxWithLabel';
import { InformativeOfClientFare } from '../../../../../components/InformativeOfClientFare';

import { isMobile, validateCPF, validateNIF } from '../../../../../utils/Util';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../../../utils/dateTime';
import formatCurrency from '../../../../../utils/formatCurrency';
import {
  Address,
  ClientFare,
  Order,
  TaxData,
} from '../../../../../models/DataResponse';
import { CustomInformationOption } from '../../../../../models/SellsConfiguration';
import { CompanyFeatures } from '../../../../../models/CompanyFeatures';

import {
  ClientFareFooterContent,
  IdentificationErrorMessage,
  RadioButtonTitle,
  RadioContent,
} from './styles';
import { DeliveryType } from '../../../../../utils/DeliveryUtil';
import { AddressInput } from './components/AddressInput';
import { useCustomerAddressInfo } from './hooks/useAddressAutocomplete';
import { ErrorDialog } from './components/ErrorDialog';

interface ClientOrderInformationProps {
  companyFeatures: CompanyFeatures;
  formRef: RefObject<HTMLFormElement>;
  handleClientFareChange: any;
  handleOrderData(key: string, value: any): void;
  slug: string;
  userInfoData?: Order;
}

const TIME_FORMAT = 'HH:mm';

export const ClientOrderInformation = ({
  companyFeatures,
  formRef,
  handleClientFareChange,
  handleOrderData,
  slug,
  userInfoData,
}: ClientOrderInformationProps) => {
  const [formData, setFormData] = useState({} as Order);
  const [isValidIdentification, setValidIdentification] = useState(true);
  const [showFareInformative, setShowFareInformative] = useState(false);
  const [selectedClientFare, setSelectedClientFare] = useState<ClientFare | any>(null);
  const [selectedOptionInfo, setSelectedOptionInfo] = useState<string>();
  const [deliveryType, setDeliveryType] = useState<string>(DeliveryType.DELIVERY);
  const [useSameAddress, setUseSameAddress] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<string>('');

  const { data: customerAddressInfo } = useCustomerAddressInfo({
    address: selectedAddress,
    isAutocompleteEnabled: !isNil(companyFeatures.location?.radius),
    storeAddress: companyFeatures.location?.address,
    radius: companyFeatures.location?.radius,
    slug,
  });

  const [addressNotAvailable, setAddressNotAvailable] = useState<boolean>(true);

  const { sellsConfiguration } = companyFeatures;
  const {
    clientFare,
    customInformation,
    hasDelivery,
    hasEatInLoco,
    hasPickUp,
    isDatetimeForDeliveryOrPickupVisible,
    isEmailRequired,
    isEmailVisible,
    isFareRequired,
    isInstagramRequired,
    isInstagramVisible,
    isTaxDataRequired,
    isTaxDataVisible,
    isTelephoneRequired,
    isTimePickerForDeliveryOrPickupVisible,
    startDatePicker,
  } = sellsConfiguration;

  const currency = sellsConfiguration.currency || 'BRL';
  const isCurrencyInEuro = currency === '€'; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52

  useEffect(() => {
    if (userInfoData) {
      setFormData(userInfoData);

      if (userInfoData.clientFare) {
        setSelectedClientFare(JSON.stringify(userInfoData.clientFare));
      }

      setDeliveryType(userInfoData.deliveryType);

      setUseSameAddress(userInfoData.deliveryType === DeliveryType.TAKE_AWAY);
    } else {
      if (hasPickUp) {
        setUseSameAddress(false);
      }

      if (hasDelivery) {
        setDeliveryType(DeliveryType.DELIVERY);
      } else if (hasPickUp) {
        setDeliveryType(DeliveryType.TAKE_AWAY);
      } else {
        setDeliveryType(DeliveryType.EAT_IN_LOCO);
      }
    }
  }, [hasDelivery, hasEatInLoco, hasPickUp, userInfoData]);

  // TODO: REMOVE THIS!
  useEffect(() => {
    if (customerAddressInfo) {
      if (companyFeatures.location?.radius) {
        setAddressNotAvailable(customerAddressInfo.isAddressAvailable);
      }

      handleOrderData('customerAddressInfo', customerAddressInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyFeatures.location, customerAddressInfo]);

  const clientFareList = useMemo(() => {
    const listFares = clientFare || [];
    return listFares.filter((fare) => fare.isEnabled);
  }, [clientFare]);

  const canShowAddressFields = useMemo(() => {
    if (!hasDelivery) {
      return false;
    }
    return deliveryType === DeliveryType.DELIVERY;
  }, [deliveryType, hasDelivery]);

  const hasMoreThanOneDeliveryType = () => {
    const trueCount = (hasDelivery ? 1 : 0) + (hasPickUp ? 1 : 0) + (hasEatInLoco ? 1 : 0);

    return trueCount > 1;
  }

  const identificationMask = useMemo(() => {
    const phone = formData.phone;

    let idMask = '';
    let idLabel = 'ID';
    if (phone?.includes('+351')) {
      idMask = '999 999 999';
      idLabel = 'NIF';
    } else if (phone?.includes('+55')) {
      idMask = '999.999.999-99';
      idLabel = 'CPF';
    }

    return {
      idLabel,
      idMask,
    };
  }, [formData.phone]);

  const isMobileDevice = useMemo(() => {
    return isMobile.any();
  }, []);

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;

    if (id.includes('.')) {
      const [prefix, key, key2] = id.split('.');

      let { taxData } = formData;
      if (!taxData || taxData === undefined) {
        taxData = {} as TaxData;
      }

      if (key2) {
        let { fiscalAddress } = taxData;
        if (!fiscalAddress || fiscalAddress === undefined) {
          fiscalAddress = {} as Address;
        }
        fiscalAddress[key2] = value;
        taxData.fiscalAddress = fiscalAddress;
      } else {
        taxData[key] = value;
      }

      setFormData({
        ...formData,
        [prefix]: {
          ...taxData,
        },
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }

    handleOrderData(id, value);
  }

  function validateIdentification() {
    if (!isTaxDataRequired) {
      setValidIdentification(true);
      return;
    }

    const identification = formData.taxData?.identification;
    if (identification) {
      setValidIdentification(
        isCurrencyInEuro
          ? validateNIF(identification)
          : validateCPF(identification)
      );
    }
  }

  const getLabelForDatetimePicker = () => {
    let datetimeArg = 'hora';
    if (isDatetimeForDeliveryOrPickupVisible && isTimePickerForDeliveryOrPickupVisible) {
      datetimeArg = 'data/hora';
    } else if (isDatetimeForDeliveryOrPickupVisible) {
      datetimeArg = 'data';
    }

    return `Escolha a ${datetimeArg} do pré-agendamento`;
  }

  const isFareOrZone = () =>
    clientFareList.filter((item) => item.price !== 0).length > 0;

  function getFareLabel(fare: ClientFare) {
    let fareLabel = `<strong>${fare.destiny}</strong>`;
    if (fare.price && fare.price !== 0) {
      fareLabel = `<strong>${fare.destiny}</strong> - ${formatCurrency(
        fare.price,
        currency
      )}`;
    }
    return `<div style='text-align: left; font-size: 14px'>${fareLabel}</div>`;
  }

  function getOptionInfoLabel(option: CustomInformationOption) {
    let label = `<strong>${option.title}</strong>`;
    if (option.description) {
      label = `${label} - ${option.description}`;
    }
    return `<div style='text-align: left; font-size: 14px'>${label}</div>`;
  }

  function renderTaxData() {
    return (
      <BoxWithLabel
        title="Dados fiscais"
        showContent={isTaxDataRequired ?? false}
      >
        <TextField
          id="taxData.name"
          variant="outlined"
          type="text"
          className="form-general-input"
          label="Nome"
          inputProps={{ maxLength: 100 }}
          value={formData.taxData?.name || ''}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          required={isTaxDataRequired}
        />

        <InputMask
          id="taxData.identification"
          mask={identificationMask.idMask}
          value={formData.taxData?.identification || ''}
          onChange={handleInputChange}
          onBlur={validateIdentification}
        >
          {() => (
            <TextField
              id="taxData.identification"
              variant="outlined"
              type="text"
              className="form-general-input"
              label={identificationMask.idLabel}
              required={isTaxDataRequired}
            />
          )}
        </InputMask>

        {!isValidIdentification && (
          <IdentificationErrorMessage>
            {isCurrencyInEuro ? 'NIF inválido' : 'CPF inválido'}
          </IdentificationErrorMessage>
        )}

        {canShowAddressFields && (
          <Checkbox
            checked={useSameAddress}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setUseSameAddress(isChecked);
              handleOrderData('canUseSameAddress', isChecked);
            }}
          >
            Usar a mesma morada de entrega?
          </Checkbox>
        )}

        {!useSameAddress && (
          <>
            <TextField
              id="taxData.fiscalAddress.address"
              variant="outlined"
              type="text"
              className="form-general-input"
              label="Morada completa"
              inputProps={{ maxLength: 100 }}
              value={formData.taxData?.fiscalAddress?.address || ''}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              style={{ marginTop: '1rem' }}
              required={isTaxDataRequired}
            />

            <TextField
              id="taxData.fiscalAddress.number"
              variant="outlined"
              type="text"
              value={formData.taxData?.fiscalAddress?.number || ''}
              className="form-general-input"
              label={'Número da porta e andar'}
              inputProps={{ maxLength: 30 }}
              onChange={handleInputChange}
              required={isTaxDataRequired}
            />

            <TextField
              id="taxData.fiscalAddress.postalCode"
              variant="outlined"
              type="text"
              className="form-general-input"
              required={isTaxDataRequired}
              label="Código Postal"
              value={formData.taxData?.fiscalAddress?.postalCode}
              inputProps={{ maxLength: 9 }}
              onChange={(event) => {
                const onlyNums = event.target.value.replace(/[^0-9-]/g, '');
                setFormData({
                  ...formData,
                  taxData: {
                    ...formData.taxData,
                    fiscalAddress: {
                      ...formData.taxData.fiscalAddress,
                      postalCode: onlyNums,
                    },
                  },
                });
                handleOrderData('taxData.fiscalAddress.postalCode', onlyNums);
              }}
            />
          </>
        )}
      </BoxWithLabel>
    );
  }

  return (
    <form ref={formRef}>
      <div className="form-general">
        {hasMoreThanOneDeliveryType() && (
          <FormControl component="fieldset">
            <RadioButtonTitle>Método de entrega</RadioButtonTitle>
            <RadioGroup
              value={deliveryType}
              onChange={(event) => {
                const value = event.target.value;
                setDeliveryType(value);

                handleOrderData('deliveryType', value);

                if (value === DeliveryType.TAKE_AWAY || value === DeliveryType.EAT_IN_LOCO) {
                  setSelectedClientFare(null);
                  handleClientFareChange(null);
                  setUseSameAddress(false);
                }
              }}
            >
              <div>
                {hasDelivery && (
                  <FormControlLabel
                    value={DeliveryType.DELIVERY}
                    control={<Radio />}
                    label="Delivery"
                  />
                )}

                {hasPickUp && (
                  <FormControlLabel
                    value={DeliveryType.TAKE_AWAY}
                    control={<Radio />}
                    label="Retirada no local"
                  />
                )}

                {hasEatInLoco && (
                  <FormControlLabel
                    value={DeliveryType.EAT_IN_LOCO}
                    control={<Radio />}
                    label="Comer no local"
                  />
                )}
              </div>
            </RadioGroup>
          </FormControl>
        )}

        {customInformation.isEnabled && (
          <BoxWithLabel
            title={customInformation.title}
          >
            {customInformation.description && <span>{customInformation.description}</span>}

            <RadioGroup
              name="optionsInfo"
              value={selectedOptionInfo}
              onChange={(event) => {
                const value = event.target.value;
                setSelectedOptionInfo(value);

                const option = JSON.parse(value) as CustomInformationOption;

                const toSave = {
                  title: customInformation.title,
                  option: {
                    title: option.title,
                  }
                }
                handleOrderData('additionalInformation', JSON.stringify(toSave));
              }}
            >
              <RadioContent>
                {customInformation.options
                  .filter(option => option.isEnabled)
                  .map((option) => {
                    return (
                      <FormControlLabel
                        key={option._id}
                        control={
                          <Radio
                            required={customInformation.isRequired}
                          />
                        }
                        value={JSON.stringify(option)}
                        label={parse(getOptionInfoLabel(option))}
                      />
                    );
                  })}
              </RadioContent>
            </RadioGroup>

            {customInformation.isRequired && (
              <FormHelperText>
                Opção obrigatória
                <span style={{ color: 'red' }}>*</span>
              </FormHelperText>
            )}
          </BoxWithLabel>
        )}

        {(isDatetimeForDeliveryOrPickupVisible || isTimePickerForDeliveryOrPickupVisible) && (
          <BoxWithLabel title={getLabelForDatetimePicker()}>
            <div style={{ display: 'flex', gap: '10px' }}>
              {isDatetimeForDeliveryOrPickupVisible && (
                <DatePicker
                  status={formData.datePickerError ? "error" : ''}
                  format={DATE_FORMAT}
                  onChange={(_, dateString) => {
                    handleOrderData('scheduledDate', dateString);
                    setFormData({ ...formData, datePickerError: false });
                  }}
                  disabledDate={(current) => {
                    let compareDateMoment = moment(moment().format(SERVER_DATE_FORMAT), SERVER_DATE_FORMAT)
                    const startDateMoment = startDatePicker && moment(startDatePicker, SERVER_DATE_FORMAT);

                    if (startDateMoment && startDateMoment >= compareDateMoment) {
                      compareDateMoment = startDateMoment;
                    }

                    return current && current < compareDateMoment;
                  }}
                  style={{ width: '100%' }}
                />
              )}

              {isTimePickerForDeliveryOrPickupVisible && (
                <TimePicker
                  status={formData.timePickerError ? "error" : ''}
                  format={TIME_FORMAT}
                  onChange={(_, timeString: string) => {
                    handleOrderData('scheduledTime', timeString);
                    setFormData({ ...formData, timePickerError: false });
                  }}
                />)}
            </div>

            <FormHelperText
              style={{
                fontStyle: 'italic',
                maxWidth: isMobileDevice ? '350px' : '',
                overflowWrap: 'break-word',
                padding: '6px 12px',
                textAlign: 'center',
              }}>
              {companyFeatures.hintMessageForDateTimePicker}
            </FormHelperText>
          </BoxWithLabel>
        )}

        {canShowAddressFields && (
          <>
            {clientFareList.length > 0 && (
              <BoxWithLabel
                title={isFareOrZone() ? 'Escolha o frete' : 'Escolha a zona'}
              >
                <RadioGroup
                  name="clientFare"
                  value={selectedClientFare}
                  onChange={(event) => {
                    const value = event.target.value;
                    setSelectedClientFare(value);

                    const fareValue = JSON.parse(value) as ClientFare;
                    handleOrderData('clientFare', fareValue);
                    handleClientFareChange(fareValue);
                  }}
                >
                  <RadioContent>
                    {clientFareList.map((fare) => {
                      return (
                        <FormControlLabel
                          key={fare._id}
                          control={
                            <Radio
                              required={isFareRequired}
                            />
                          }
                          value={JSON.stringify(fare)}
                          label={parse(getFareLabel(fare))}
                        />
                      );
                    })}
                  </RadioContent>
                </RadioGroup>

                <ClientFareFooterContent>
                  {isFareRequired && (
                    <FormHelperText>
                      {isFareOrZone() ? 'Frete' : 'Zona'} obrigatório
                      <span style={{ color: 'red' }}>*</span>
                    </FormHelperText>
                  )}
                  <span
                    id="moreDetails"
                    onClick={() => setShowFareInformative(true)}
                  >
                    Mais detalhes
                  </span>
                </ClientFareFooterContent>

                {showFareInformative && (
                  <InformativeOfClientFare
                    clientFareList={clientFareList}
                    currency={currency}
                    closePopUp={() => setShowFareInformative(false)}
                  />
                )}
              </BoxWithLabel>
            )}

            <BoxWithLabel
              title={isCurrencyInEuro ? 'Sua morada' : 'Seu endereço'}
            >
              {!addressNotAvailable && (
                <ErrorDialog
                  title={`${isCurrencyInEuro ? 'A sua morada' : 'O seu endereço'} está fora do raio de entrega 😔`}
                  error=""
                  onClose={() => { setAddressNotAvailable(true) }} />
              )}

              <AddressInput
                autocomplete={!isNil(companyFeatures.location?.radius)}
                address={formData.address}
                isCurrencyInEuro={isCurrencyInEuro}
                onAddressSelected={(address) => {
                  setSelectedAddress(address);
                  handleOrderData('address', address);
                  setFormData({
                    ...formData,
                    address: address,
                  });
                }}
              />

              <TextField
                id="complement"
                variant="outlined"
                type="text"
                value={formData.complement || ''}
                className="form-general-input"
                label="Complemento"
                inputProps={{ maxLength: 100 }}
                onChange={handleInputChange}
              />

              <TextField
                id="postalCode"
                variant="outlined"
                type="text"
                className="form-general-input"
                label="Código Postal"
                value={formData.postalCode || customerAddressInfo?.postalCode || ''}
                inputProps={{ maxLength: 9 }}
                onChange={(event) => {
                  const onlyNums = event.target.value.replace(/[^0-9-]/g, '');
                  setFormData({
                    ...formData,
                    postalCode: onlyNums,
                  });
                  handleOrderData('postalCode', onlyNums);
                }}
              />

              <TextField
                id="referencePoint"
                variant="outlined"
                type="text"
                className="form-general-input"
                value={formData.referencePoint || ''}
                label={
                  isCurrencyInEuro
                    ? 'Alguma referência especial a acrescentar?'
                    : 'Ponto de referência'
                }
                inputProps={{ maxLength: 100 }}
                onChange={handleInputChange}
                helperText={'Facilite a chegada do entregador.'}
              />
            </BoxWithLabel>
          </>
        )}

        <BoxWithLabel title="Identifique-se">
          <TextField
            className="form-general-input"
            helperText="Campo obrigatório"
            id="name"
            inputProps={{ maxLength: 50 }}
            label={
              isCurrencyInEuro
                ? 'Nome e Apelido para envio'
                : 'Qual o seu nome?'
            }
            onChange={handleInputChange}
            required
            type="text"
            value={formData.name || ''}
            variant="outlined"
          />

          <PhoneInput
            className="phone-input-styles"
            defaultCountry={isCurrencyInEuro ? 'PT' : 'BR'}
            placeholder={isCurrencyInEuro ? 'Nr Telemóvel' : 'Celular'}
            rules={{
              required: isTelephoneRequired ?? true,
            }}
            value={formData.phone || ''}
            onChange={(value) => {
              if (value === undefined) {
                value = '';
              }

              setFormData({
                ...formData,
                phone: value.toString(),
              });
              handleOrderData('phone', value.toString());
            }}
          />
          {isTelephoneRequired && (
            <FormHelperText style={{ marginLeft: '14px' }}>
              Telefone obrigatório
            </FormHelperText>
          )}

          {isEmailVisible && (
            <TextField
              id="email"
              variant="outlined"
              type="text"
              className="form-general-input"
              label="Email"
              value={formData.email || ''}
              onChange={handleInputChange}
              style={{ marginTop: '16px' }}
              required={isEmailRequired}
            />
          )}

          {isInstagramVisible && (
            <TextField
              id="instagram"
              variant="outlined"
              type="text"
              className="form-general-input"
              label="Instagram"
              value={formData.instagram || ''}
              onChange={handleInputChange}
              style={{
                marginTop: isEmailVisible ? '0' : '16px',
              }}
              required={isInstagramRequired}
            />
          )}
        </BoxWithLabel>

        {isTaxDataVisible && renderTaxData()}
      </div>
    </form>
  );
};
