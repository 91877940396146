import React from 'react';
import { Table, Typography } from 'antd';
import { ComplementOfProduct } from '../../../../../../models/DataResponse';
import Column from 'antd/lib/table/Column';
import { RoundTag } from '../../../../../../common-styles';

type ExpandedComplementItemsProps = {
    complements: ComplementOfProduct[];
}

export const ExpandedComplementItems = ({ complements }: ExpandedComplementItemsProps) => {
    return (
        <div style={{ marginTop: '16px' }}>
            <Typography.Title level={4}>Complementos</Typography.Title>

            <Table
                bordered
                dataSource={[...complements]}
                pagination={false}
            >
                <Column
                    title="Status"
                    width={80}
                    render={(complement: ComplementOfProduct) => {
                        const color = complement.isEnabled ? 'green' : 'red';
                        return (
                            <RoundTag color={color} key={complement._id}>
                                {complement.isEnabled ? 'Ativo' : 'Pausado'}
                            </RoundTag>
                        )
                    }}
                />
                <Column
                    title="Tipo"
                    width={100}
                    render={(complement: ComplementOfProduct) => {
                        const color = complement.isRequired ? '#717171' : undefined;
                        return (
                            <RoundTag color={color} key={complement._id}>
                                {complement.isRequired ? 'OBRIGATÓRIO' : 'OPCIONAL'}
                            </RoundTag>
                        );
                    }}
                />
                <Column title="Título" dataIndex="title" />
                <Column
                    title="Itens"
                    render={(complement: ComplementOfProduct) => <ul>
                        {complement.complementItemOptions.map(item => {
                            return <li style={{ marginLeft: '10px' }}>{item.title}</li>
                        })}
                    </ul>}
                />
            </Table>
        </div>
    );
}