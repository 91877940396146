import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  TextField,
} from '@material-ui/core';

/**@deprecated */
export const tooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(2),
    },
    absolute: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
  })
);

/**@deprecated */
export const accordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'bold',
      fontFamily: 'Ubuntu',
      color: 'white',
    },
    iconColor: {
      color: 'white',
    },
  })
);

/**@deprecated */
export const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: '500',
    },
  },
})(TextField);

export const FormLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
