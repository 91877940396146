import React from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';

import LoaderSpinner from '../../../components/LoaderSpinner';
import { PageNotFound } from '../../../components/PageNotFound';
import { QrLinkItem } from './components/QrLinkItem';

import {
  Container,
  Content,
  CustomLink,
  Grid,
  ImageItem,
  LogoContainer,
  LogoContent,
  Subtitle,
  Title,
} from './styles';
import { useQrlinkBySlug } from '../../../hooks/useQrlinks';
import { HeaderMetadata } from '../../../components/HeaderMetadata';
import { Footer } from '../../../components/Footer';

export const QRLinksPage = () => {
  const { slug: clientSlug } = useParams();

  const { data, isFetching, isError, error } = useQrlinkBySlug(clientSlug);

  if (isFetching) {
    return <LoaderSpinner />;
  }

  if (isError || !data) {
    const message = {
      local: 'qrlink - LandingPage',
      error,
      isError,
      data,
    }
    Sentry.captureMessage(JSON.stringify(message));
    return <PageNotFound />;
  }

  const { company, linkTree } = data;

  return (
    <>
      <HeaderMetadata title={company?.fantasyName} />

      <Container>
        <Content>
          <LogoContainer>
            {company.logoUrl && (
              <LogoContent
                src={company.logoUrl}
                alt="menuonline"
                dimens={company.styles?.logoDimens}
              />
            )}
          </LogoContainer>

          <Title>{linkTree.title}</Title>

          {linkTree.subtitle && (
            <Subtitle color={company.styles?.titleColor}>
              {linkTree.subtitle}
            </Subtitle>
          )}

          {linkTree.isGrid ? (
            <Grid>
              {linkTree.links.map((link) => <QrLinkItem key={link._id} linkPage={link} />)}
            </Grid>
          ) : (
            linkTree.links.map((link) => {
              return (
                <CustomLink
                  key={link.name}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.name}
                  {link.imageUrl && <ImageItem src={link.imageUrl} />}
                </CustomLink>
              );
            })
          )}
        </Content>

        <Footer
          instagram={company?.instagram}
          facebook={company?.facebook}
          whatsapp={company?.whatsapp}
          phone={company?.phoneFooter}
          website={company?.websiteFooter}
        />
      </Container>
    </>
  );
};
