
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageNotFound } from "../components/PageNotFound";
import OrderDetailsStaff from "../pages/backoffice/OrderDetailsStaff";
import { DynamicMenu } from "../pages/clients/MenuOnline";
import { CustomersPage } from "../pages/public/Customers";
import { DemoPage } from "../pages/public/Demo";
import { FeedbackPage } from "../pages/public/Feedback";
import { OrderStatusPage } from "../pages/public/OrderStatus";
import { QRLinksPage } from "../pages/public/QRLinks";
import { SessionPage } from "../pages/backoffice/Session";
import OpenTestMenus from '../pages/public/Demo/test-menus';
import { Rodeadouro } from "../pages/clients/Rodeadouro";

export const General = () => {
  const isDefaultPlatform = process.env.REACT_APP_ENV_TYPE === "QRCODE_PREFERIDO";

  const getDefaultPath = (): string => {
    switch (process.env.REACT_APP_ENV_TYPE) {
      case 'NOW': return "/qrlinks/now";
      case 'GRUPOFAZENDA': return "/qrlinks/tonever";
      default: return "/clientes";
    }
  }

  const RedirectToGrupoTonever = (props: any) => {
    window.location.href = `https://app.tonever.pt/${props.path}`;
    return null;
  }

  return (
    <Routes>
      <Route element={<SessionPage />} path="/session" />
      <Route path="/login" element={<Navigate to="/session#login" />} />
      <Route path="/signup" element={<Navigate to="/session#signup" />} />
      <Route path="/forgotpassword" element={<Navigate to="/session#forgotpassword" />} />

      <Route element={<OrderDetailsStaff />} path="/sysadmin/order-detail" />

      <Route element={<DynamicMenu />} path="/:menu" />
      <Route element={<FeedbackPage />} path="/feedback/:client" />
      <Route element={<QRLinksPage />} path="/qrlinks/:slug" />
      <Route element={<OrderStatusPage />} path="/order-status/:slug/:order" />
      <Route element={<PageNotFound />} path="/404" />

      {isDefaultPlatform && (
        <>
          {/* Static Clients */}
          <Route element={<Rodeadouro />} path="/rodeadouro" />

          {/* QRCode PReferido */}
          <Route element={<DemoPage />} path="/demo/" />
          <Route element={<CustomersPage />} path="/clientes/" />
          <Route element={<OpenTestMenus />} path="/all-menus/" />
        </>
      )}

      {isDefaultPlatform && (
        <>
          <Route element={<RedirectToGrupoTonever path={"/fazenda-minas-pizzaria"} />} path="/fazenda-minas-pizzaria" />
          <Route element={<RedirectToGrupoTonever path={"/fazenda-minas-loures"} />} path="/fazenda-minas-loures" />
          <Route element={<RedirectToGrupoTonever path={"/fazenda-minas-forte-da-casa"} />} path="/fazenda-minas-forte-da-casa" />
        </>
      )}

      {/* TODO: To be removed in the next 2 deploys */}
      <Route path="/sysadmin/home" element={<Navigate to="/session#login" />} />

      <Route element={<Navigate to={getDefaultPath()} />} path="/" />
    </Routes>
  );
};