import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  bottom: 2%;
  right: 25%;
  margin-right: 25px;
  z-index: 2;
  overflow: hidden;
  height: 75px;
  width: 75px;
  border-radius: 10px;
  transition: height 0.4s 0.1s, width 0.4s 0.1s, box-shadow 0.3s;
  transition-timing-function: cubic-bezier(0.67, 0.17, 0.32, 0.95);
  background-color: white;
  box-shadow: 0px 4px 12px -6px black;

  @media (min-width: 1440px) {
    right: 34%;
  }

  @media (min-width: 1030px) {
    right: 18%;
  }

  @media (max-width: 1024px) {
    right: 12%;
  }

  @media (max-width: 800px) {
    right: 2%;
  }

  @media (max-width: 500px) {
    right: 0;
    margin-right: 10px;
  }

  div {
    padding: 0;
    display: flex;
    align-items: center;

    > img {
      margin-top: 12px;
      height: 42px;
      width: 42px;
    }
  }

  > p {
    font-size: 13px;
    font-weight: 500;
    color: black;
  }
`;
