import React from 'react';
import { Accordion, AccordionDetails, FormHelperText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert';

import { OpeningHours } from '../../../../../../../models/DataResponse';
import { WeekdaysList } from '../../../../../../../utils/Constants';
import { InputLabel } from '../../../../../../../common-styles';

type WorkScheduleListProps = {
  isSellsEnabled: boolean;
  items: OpeningHours[];
  deleteAction(id?: string): void;
  editAction(openingHour: OpeningHours): void;
};

const WorkScheduleList: React.FC<WorkScheduleListProps> = ({
  isSellsEnabled,
  items,
  deleteAction,
  editAction,
}) => {
  function handleDeleteAction(openHour: OpeningHours) {
    confirmAlert({
      title: 'Atenção',
      message: `Tem certeza que deseja excluir o horário ${getWeekdays(
        openHour.weekdays
      )}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => deleteAction(openHour?._id),
        },
        {
          label: 'Não',
          onClick: () => {},
        },
      ],
    });
  }

  function handleEditAction(openHour: OpeningHours) {
    editAction(openHour);
  }

  function getWeekdays(weekdays: number[]) {
    return weekdays.map((day) => WeekdaysList[day].substring(0, 3)).join(', ');
  }

  const getHoursFormatted = (start: string, end: string) => `${start} - ${end}`;

  return (
    <>
      {isSellsEnabled && (
        <InputLabel style={{ marginTop: '20px' }}>Horários</InputLabel>
      )}
      <Accordion
        style={{ backgroundColor: 'transparent', marginTop: '-2px' }}
        defaultExpanded={true}
      >
        <AccordionDetails className="form-general">
          <List>
            {items.map((option, index) => {
              return (
                <ListItem
                  key={option._id}
                  style={{ background: index % 2 === 0 ? '#f2f2f2' : '' }}
                >
                  <ListItemText
                    id={option._id}
                    primary={getWeekdays(option.weekdays)}
                    secondary={getHoursFormatted(
                      option.startHour,
                      option.endHour
                    )}
                  />
                  <ListItemSecondaryAction style={{ display: 'flex' }}>
                    <EditIcon
                      onClick={() => handleEditAction(option)}
                      style={{ cursor: 'pointer' }}
                    />
                    <DeleteIcon
                      onClick={() => handleDeleteAction(option)}
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>

          <FormHelperText
            style={{
              marginBottom: '6px',
              marginTop: '15px',
              fontStyle: 'italic',
              fontWeight: 500,
            }}
          >
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Os dias que não possuirem horário, irão ser automaticamente
            considerados como dias fechados.
          </FormHelperText>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default WorkScheduleList;
