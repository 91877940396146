import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import { Button, Empty, Spin } from 'antd';

import { Features } from '../../../../models/Features';
import {
  Category,
  FeaturesMenuOptions,
} from '../../../../models/DataResponse';
import { Product, ProductItem } from '../../../../models/Product';
import { ActionType, Role } from '../../../../models/Enum';

import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../components/Notification';
import {
  PanelResult,
  PanelResultStatus,
} from '../../../../components/PanelResult';
import { ProductTable } from './components/ProductTable';
import { useLoggedUser, useProduct } from '../../../../hooks';
import { ProductService } from '../../../../services/ProductService';

interface ProductSectionProps {
  categories: Category[];
  company: string;
  features?: Features;
  handleAddAction: (categoryId: string) => void;
  handleCloneClick: (item: Product) => void;
  handleRowClick: (item: Product) => void;
  isLoading: boolean;
  isSearching: boolean;
  pageProductDismissed: () => void;
  products: Product[];
}

export const ProductsTable = ({
  categories,
  company,
  features,
  handleAddAction,
  handleCloneClick,
  handleRowClick,
  isLoading,
  isSearching,
  pageProductDismissed,
  products,
}: ProductSectionProps) => {
  const [productList, setProductList] = useState<ProductItem[]>([]);
  const { userLogged } = useLoggedUser();
  const { deleteProduct, update, isLoadingUpdate, isLoadingDelete } = useProduct();

  const tabOptions: FeaturesMenuOptions[] = features?.menuOptions || [];

  useEffect(() => {
    let serializedList = categories
      .filter((item) => item.isEnabled && item.isSectionType)
      .map((catItem) => {
        return {
          category: catItem,
          items: products
            .filter((catalog) => catalog.categoryId === catItem._id)
            .sort((a, b) => a.order - b.order),
        };
      });

    if (isSearching) {
      serializedList = serializedList.filter((item) => item.items.length > 0);
    }

    setProductList(serializedList);
  }, [categories, products, isSearching]);

  const translateTabNames = (options: string[]) => {
    return options
      .map((option) => {
        if ('all' === option) {
          return 'Todos';
        }

        return tabOptions?.find((item) => item._id === option)?.name ?? '';
      })
      .join(', ');
  };

  function handleAction(product: Product, action: ActionType) {
    switch (action) {
      case ActionType.OPEN:
        handleRowClick(product);
        break;
      case ActionType.UPDATE:
        updateProduct(product);
        break;
      case ActionType.DELETE:
        executeDeletion(product);
        break;
      case ActionType.CLONE: {
        const clonedObject = { ...product };
        delete clonedObject._id;
        clonedObject.imageUrl = ''; // TODO: This is just a workaround, we need to investigate why the clone is not working fine when the user deletes the image.
        handleCloneClick(clonedObject);
      }
    }
  }

  const executeDeletion = async (product: Product) => {
    deleteProduct(
      { ...product },
      {
        onSuccess: () => {
          NotificationDispatcher({
            message: 'Operação realizada com sucesso',
          });
          pageProductDismissed();
        },
        onError: () => {
          NotificationDispatcher({
            message: 'Erro ao tentar remover produto',
            type: NotificationType.ERROR,
          });
        }
      });
  }

  const updateProduct = async (product: Product) => {
    update(
      { ...product },
      {
        onSuccess: () => {
          NotificationDispatcher({
            message: 'Operação realizada com sucesso',
          });
          pageProductDismissed();
        },
        onError: () => {
          NotificationDispatcher({
            message: 'Erro ao tentar atualizar produto',
            type: NotificationType.ERROR,
          });
        }
      });
  }

  const updatePositionsOnDatabase = async (
    updatedListPositions: Product[],
    productItem: ProductItem,
    index: number
  ) => {
    const updatedOrders = updatedListPositions.map((item, orderPosition) => {
      return {
        ...item,
        order: orderPosition,
      };
    });

    productItem.items = updatedOrders;
    productList[index] = productItem;
    setProductList([...productList]);

    try {
      await ProductService.updatePositionsOnDatabase(company, updatedOrders);
      NotificationDispatcher({
        message: 'Posição atualizada',
      });
    } catch (error) {
      console.error('Failed update list position', error);
      NotificationDispatcher({
        message: 'Erro ao tentar atualizar posição',
        type: NotificationType.ERROR,
      });
    }
  }

  if (!isLoading && categories.length === 0) {
    let title = "Nenhum produto cadastrado";
    let subtitle = "Para criar o seu primeiro produto, é preciso que se tenha uma categoria criada.";
    if (userLogged.user.role === Role.ADMIN || userLogged.user.role === Role.MANAGER) {
      title = "Selecione uma Empresa";
      subtitle = "Para listar os produtos.";
    }
    return (
      <PanelResult
        status={PanelResultStatus.INFO}
        title={title}
        subtitle={subtitle}
      />
    );
  }

  return (
    <>
      {Boolean(productList.length) ? productList.map((productItem, index) => {
        const { category, items } = productItem;

        return (
          <div key={category._id}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <h2>{category.category}</h2>
              {category.tabsAssociated && category.tabsAssociated.length > 0 && (
                <small style={{ marginLeft: '5px', marginBottom: '5px' }}>
                  ({category.tabsAssociated.length > 1 ? 'Abas: ' : 'Aba: '}
                  {translateTabNames(category.tabsAssociated)})
                </small>
              )}
            </div>

            <h5 style={{ color: 'gray', overflowWrap: 'break-word' }}>
              {category.description && parse(category.description)}
            </h5>

            <Button
              type="primary"
              onClick={() => handleAddAction(category._id)}
            >
              <i
                className="fa fa-plus-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Adicionar
            </Button>

            <Spin tip="Carregando..." spinning={isLoadingUpdate || isLoadingDelete}>
              <ProductTable
                isLoading={isLoading}
                products={items}
                onTableChanged={(items) =>
                  updatePositionsOnDatabase(items, productItem, index)
                }
                handleAction={handleAction}
              />
            </Spin>
          </div>
        );
      }) : <Empty description={'Nenhum resultado encontrado'}/>}
    </>
  );
};
