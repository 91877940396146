import * as Sentry from '@sentry/browser';
import api from '../../../services/api';

import { Company } from '../../../models/Company';
import {
  FeaturesMenuOptions,
  MenuCatalog,
  MenuConfiguration,
  Order,
} from '../../../models/DataResponse';

import {
  getOperatingSystem,
  removeAccents,
} from '../../../utils/Util';
import { isToday } from '../../../utils/dateTime';
import { sanitizePrice } from '../../../utils/priceUtil';
import { PREFIX_LOCALSTORAGE } from '../../../utils/Constants';
import { OrderBody, prepareOrderToSend } from '../../../utils/PrepareOrderToSendUtil';
import { CLIENT_INFO_DATA, LocalStorageService } from '../../../services/LocalStorageService';
import { getComplementOrderMessage } from '../../../utils/OrderUtil';

export interface SendOrderData {
  companyName: string;
  companySlug: string;
  menuConfiguration: MenuConfiguration;
  order: Order;
}

export const ALL_KEY = 'Todos';
class MenuController {

  private getSanitizedTabName(value: string): string {
    return removeAccents(value).toLowerCase();
  }

  getSelectedMenuOption = (
    options: FeaturesMenuOptions[],
    selectedTab?: string
  ) => {
    const today = new Date();

    let selected =
      selectedTab && selectedTab !== undefined
        ? options.find(
          (option) =>
            this.getSanitizedTabName(option.name) ===
            this.getSanitizedTabName(selectedTab)
        ) ?? options[0]
        : options[0];

    // If selectedTab is not undefined, that means we received an access from a shared link
    // We'll ignore others configurations.
    if (selectedTab) {
      return selected;
    }

    const weekdays = options.filter((item) => item.weekdays !== 'everyday');
    if (weekdays.length > 0) {
      weekdays.forEach((item) =>
        item.weekdays.split('|').forEach((day) => {
          if (Number(day) === today.getDay()) {
            selected = item;
          }
        })
      );
    } else {
      const customHours = options.filter((item) => item.allDay === false);
      if (customHours.length > 0) {
        let minutes = `${today.getMinutes()}`;
        if (today.getMinutes() < 10) {
          minutes = `0${minutes}`;
        }

        let hours = `${today.getHours()}`;
        if (today.getHours() < 10) {
          hours = `0${hours}`;
        }

        const todayHours = `${hours}:${minutes}`;
        const filteredByHours = customHours.filter((item) => {
          return (
            todayHours.localeCompare(item.startHour) >= 0 &&
            todayHours.localeCompare(item.endHour) <= 0
          );
        });

        if (filteredByHours.length > 0) {
          selected = filteredByHours[0];
        }
      }
    }

    return selected;
  };

  filterMenuOptionsByConfig = (
    menuOptionsShouldBeHidden: boolean,
    options: FeaturesMenuOptions[]
  ) => {
    if (menuOptionsShouldBeHidden) {
      const today = new Date();

      const weekDays = options.filter((item) => item.weekdays !== 'everyday');
      if (weekDays.length > 0) {
        options = options.filter((item) => {
          return (
            item.weekdays === 'everyday' ||
            item.weekdays
              .split('|')
              .some((day) => Number(day) === today.getDay())
          );
        });
      }

      let minutes = `${today.getMinutes()}`;
      if (today.getMinutes() < 10) {
        minutes = `0${minutes}`;
      }

      let hours = `${today.getHours()}`;
      if (today.getHours() < 10) {
        hours = `0${hours}`;
      }

      const todayHours = `${hours}:${minutes}`;
      const customHours = options.filter((item) => item.allDay === false);
      if (customHours.length > 0) {
        options = options.filter((item) => {
          return (
            item.allDay === true ||
            (todayHours.localeCompare(item.startHour) >= 0 &&
              todayHours.localeCompare(item.endHour) <= 0)
          );
        });
      }
    }

    return options.sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
      return -1;
    });
  };

  doFilterMenu = (
    selectedMenuOption: FeaturesMenuOptions,
    menuCatalog: MenuCatalog[]
  ) => {
    const result = menuCatalog.filter((menuItem) => {
      const availableTabsName = menuItem.tabsAssociated.map((tab) => tab.name);
      return (
        availableTabsName.includes(selectedMenuOption.name) ||
        availableTabsName.includes(ALL_KEY)
      );
    });

    return result;
  };

  sanitizeValue = (price: string): string => {
    return sanitizePrice(price);
  };

  trackClientAccess = async (slug: string, companyId?: string) => {
    if (!companyId) return;

    try {
      const savedData = localStorage.getItem(`${PREFIX_LOCALSTORAGE}${slug}`);

      if (savedData) {
        const clientAccessTimestap = new Date(savedData);
        if (isToday(clientAccessTimestap)) {
          return;
        }
      }

      const body = {
        platform: getOperatingSystem(),
        companySlug: slug,
        companyId: companyId,
        isNewUser: savedData ? false : true,
      };

      localStorage.setItem(`${PREFIX_LOCALSTORAGE}${slug}`, new Date().toString());

      await api.post('api/trackClients', body);
    } catch (err) {
      Sentry.captureException(err);
      console.error('Error calling TrackClients POST API', err);
    }
  };

  getTitleColor = (company: Company | undefined) => {
    let titleColor = company?.styles?.titleColor;
    if (titleColor) {
      if (!titleColor.includes('#')) {
        titleColor = `#${titleColor}`;
      }
      return titleColor;
    }

    return '#000000';
  };

  getSubTitleColor = (company: Company | undefined) => {
    let subTitleColor = company?.styles?.subTitleColor;
    if (!subTitleColor) {
      return this.getTitleColor(company);
    }

    if (!subTitleColor.includes('#')) {
      subTitleColor = `#${subTitleColor}`;
    }
    return subTitleColor;
  };

  canLogInAnalyticsFirebase = (): boolean => {
    const accessUrl = window.location.href;
    return !(
      accessUrl.includes('localhost') ||
      accessUrl.includes('qrcode-preferido-site-dev.web.app')
    );
  };

  // TODO: move this to a dedicated hook
  sendOrder = async ({ companyName, companySlug, menuConfiguration, order }: SendOrderData) => {
    const { sendLinkToFollowOrder } = menuConfiguration.companyFeatures.sellsConfiguration;

    const orderBody = prepareOrderToSend({
      companyName,
      menuConfiguration,
      order,
    });
    const response = await this.dispatchOrderToServer(orderBody);

    this.saveClientIntoDataLocally(companyName, order);

    return getComplementOrderMessage(
      sendLinkToFollowOrder,
      response?.message,
      companySlug,
      response?.order?.orderId,
    );
  };

  // TODO: Move this to a dedicated hook
  private dispatchOrderToServer = async (body: OrderBody) => {
    try {
      const response = await api.post('api/orders', body);
      const { order, message } = response.data.data;
      return { order, message };
    } catch (err) {
      Sentry.captureException(err);
      console.error('Error calling Order POST', err);
    }
  };

  private saveClientIntoDataLocally(companyName: string, order: Order) {
    const orderCloned = {
      ...order,
      coupon: undefined,
      observation: '',
      scheduledTime: '',
      scheduledDate: '',
    };

    LocalStorageService.save(`${CLIENT_INFO_DATA}-${companyName}`, orderCloned);
  }
}

export default MenuController;
