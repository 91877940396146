import React from 'react';
import parse from 'html-react-parser';
import shortid from 'shortid';

import { calculateTotalPrice, formattedTotalPrice } from '../../../../../utils/ProductUtil';
import {
  MenuItem,
  OrderComplementExhibition,
} from '../../../../../models/DataResponse';

import {
  EditLabel,
  LabelButtonContainer,
  ProductComplementItemOption,
  ProductComplements,
  ProductComplementTitle,
  ProductDescription,
  ProductOrderItemContainer,
  ProductPrice,
  ProductTitle,
  RemoveLabel,
} from './styles';

interface ProductOrderItemProps {
  product: MenuItem;
  currency: string;
  orderComplementExhibition: OrderComplementExhibition;
  handleDeleteAction: any;
  handleEditAction: any;
}

const ProductOrderItem = ({
  product,
  currency,
  orderComplementExhibition,
  handleDeleteAction,
  handleEditAction,
}: ProductOrderItemProps) => {
  const { name, description, price, counter, extraNote, selectedComplements } =
    product;

  const totalPrice = calculateTotalPrice([product]);
  let priceStr = price;
  if (!isNaN(Number(totalPrice))) {
    priceStr = formattedTotalPrice(currency, totalPrice);
  }

  return (
    <ProductOrderItemContainer>
      <div key={`${product._id}${shortid.generate()}`}>
        <ProductTitle>{name && `(${counter}x) ${parse(name)}`}</ProductTitle>
        <ProductDescription>
          {description && parse(description)}
        </ProductDescription>
        {selectedComplements && (
          <ProductComplements>
            <ul>
              {selectedComplements?.map((complements) => {
                return (
                  <>
                    {orderComplementExhibition.showTitle && (
                      <ProductComplementTitle>
                        {complements.title}
                      </ProductComplementTitle>
                    )}

                    {complements.complementItemOptions
                      .filter(
                        (item) =>
                          item.counter !== undefined && item.counter !== 0
                      )
                      .map((option) => {
                        return (
                          <ProductComplementItemOption
                            showTitle={orderComplementExhibition.showTitle}
                            key={option._id}
                          >
                            {`${option.counter}x ${option.title}`}
                          </ProductComplementItemOption>
                        );
                      })}
                  </>
                );
              })}
            </ul>
          </ProductComplements>
        )}
        {extraNote && (
          <>
            <br />
            <ProductDescription>Obs.: {extraNote}</ProductDescription>
          </>
        )}
        <LabelButtonContainer>
          <EditLabel onClick={handleEditAction}>Editar</EditLabel>
          <RemoveLabel onClick={handleDeleteAction}>Remover</RemoveLabel>
        </LabelButtonContainer>
      </div>

      <ProductPrice>{priceStr}</ProductPrice>
    </ProductOrderItemContainer>
  );
};

export default ProductOrderItem;
