import { action, makeObservable, observable } from 'mobx';
import shortid from 'shortid';
import {
  ComplementItemOption,
  ComplementOfProduct,
  MenuItem as Product,
} from '../../models/DataResponse';

interface ProductStore {
  addAllProducts(products: Product[]): void;
  addProduct(product: Product): void;
  clearProducts(): void;
  complements: ComplementOfProduct[];
  findProducts(id: string): Product | null;
  getComplementsCounter(): void;
  products: Product[];
  removeProduct(id: string): void;
  updateProduct(product: Product): void;
}

export class ProductStoreImpl {
  private static instance: ProductStoreImpl;
  products: Product[] = [];
  complementsMap: Map<string, ComplementOfProduct[]> = new Map();
  productsWithoutComplement: Product[] = [];

  private constructor() {
    makeObservable(this, {
      products: observable,
      addAllProducts: action,
      addProduct: action,
      removeProduct: action,
      updateProduct: action,
    });
  }

  public static getInstance(): ProductStoreImpl {
    if (!ProductStoreImpl.instance) {
      ProductStoreImpl.instance = new ProductStoreImpl();
    }
    return ProductStoreImpl.instance;
  }

  clearProducts() {
    this.products = [];
  }

  addAllProducts(products: Product[]) {
    this.products = products;

    products.forEach((product) => {
      if (
        product.selectedComplements &&
        product.selectedComplements.length > 0
      ) {
        this.addComplements(product.transientId, product.selectedComplements);
      } else {
        this.productsWithoutComplement.push(product);
      }
    });
  }

  addProduct(product: Product) {
    const cloneProduct = { ...product, transientId: shortid.generate() };
    this.products.push(cloneProduct);

    if (
      cloneProduct.selectedComplements &&
      cloneProduct.selectedComplements.length > 0
    ) {
      this.addComplements(
        cloneProduct.transientId,
        cloneProduct.selectedComplements
      );
    } else {
      this.productsWithoutComplement.push(product);
    }
  }

  removeProduct(id: string) {
    this.products = this.products.filter((prod) => prod.transientId !== id);
    this.productsWithoutComplement = this.productsWithoutComplement.filter(
      (prod) => prod.transientId !== id
    );
    this.removeComplements(id);
  }

  updateProduct(product: Product) {
    const index = this.products.findIndex(
      (prod) => prod.transientId === product.transientId
    );
    this.products[index] = product;

    if (product.selectedComplements && product.selectedComplements.length > 0) {
      this.updateComplements(product.transientId, product.selectedComplements);
    } else {
      const indexComplement = this.productsWithoutComplement.findIndex(
        (prod) => {
          if (prod.transientId) {
            return prod.transientId === product.transientId;
          }
          return prod._id === product._id;
        }
      );
      this.productsWithoutComplement[indexComplement] = product;
    }
  }

  findProducts(id: string): Product[] {
    return this.products.filter(product => product._id === id)
  }

  private addComplements(
    productKey: string,
    complements: ComplementOfProduct[]
  ) {
    if (this.complementsMap.has(productKey)) {
      const prevItems = this.complementsMap.get(productKey) || [];
      this.complementsMap.set(productKey, [...prevItems, ...complements]);
    } else {
      this.complementsMap.set(productKey, complements);
    }
  }

  private updateComplements(
    productKey: string,
    complements: ComplementOfProduct[]
  ) {
    this.complementsMap.set(productKey, complements);
  }

  private removeComplements(productKey: string) {
    this.complementsMap.delete(productKey);
  }

  getComplementsCounter() {
    const values = this.complementsMap.values();
    let counter = 0;
    let computedOptions: ComplementItemOption[] = [];

    for (const complements of values) {
      for (let i = 0; i < complements.length; i++) {
        const options = complements[i].complementItemOptions;
        computedOptions = computedOptions.concat(options);
      }
    }

    computedOptions
      .filter((item) => item.counter !== undefined && item.counter !== 0)
      .forEach((option) => (counter += option.counter));

    this.productsWithoutComplement.forEach(
      (product) => (counter += product.counter)
    );
    return counter;
  }
}

export const ProductStore = ProductStoreImpl.getInstance();
