import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';

import * as Sentry from '@sentry/browser';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';

import { observer } from 'mobx-react';
import { ProductStore } from '../../../hooks/ProductStore/productsStore';
import { useBootMenu } from '../../../hooks';

import MenuController from './MenuController';
import { logEstablishmentEvent } from '../../../services/firebase';

import { AboutDetails } from './components/AboutDetails';
import { FloatingFeedbackButton } from '../../../components/FloatingFeedbackButton';
import { FloatingShoppingButton } from '../../../components/FloatingShoppingButton';
import { Footer } from '../../../components/Footer';
import { InformativeOfSellsPopUp } from '../../../components/InformativeOfSellsPopUp';
import { MainContent } from './components/MainContent';
import { NoConnection } from '../../../components/NoConnection';
import { OrderProductDetail } from './components/OrderProductDetail';
import { PageNotFound } from '../../../components/PageNotFound';
import { TabOptions } from './components/TabOptions';
import { ModalImage } from '../../../components/ModalImage';
import { ResumeOrder } from './components/OrdersViaWhatsapp/ResumeOrder';
import Spinner from '../../../components/LoaderSpinner';

import {
  MenuCatalog,
  FeaturesMenuOptions,
  MenuItem,
} from '../../../models/DataResponse';
import { removeAccents } from '../../../utils/Util';

import {
  MainContainer,
  CoverContent,
  LogoContent,
  SeeMoreButton,
} from './styles';
import './styles.css'; // FIXME: To be removed
import { HeaderMetadata } from '../../../components/HeaderMetadata';
import { useCheckPendingPaymentProcess } from './hooks/useCheckPendingPaymentProcess';
import { PaymentLoadingModal } from '../../../components/PaymentLoadingModal';

const menuController = new MenuController();

export const DynamicMenu = observer(() => {
  const [menuNotFound, setMenuNotFound] = useState(false);
  const [showingProductDetail, setShowingProductDetail] = useState(false);
  const [alreadyShownSellsViaWhatsapp, setAlreadyShownSellsViaWhatsapp] = useState(false);
  const [isProductUpdate, setProductUpdate] = useState(false);
  const [data, setData] = useState<MenuCatalog[]>([]);
  const [dataFiltered, setDataFiltered] = useState<MenuCatalog[]>([]);
  const [menuOptions, setMenuOptions] = useState<FeaturesMenuOptions[]>([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState<FeaturesMenuOptions | null>(null);

  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | any>(null);
  const [isAboutDetailsOpen, setAboutDetailsOpen] = useState(false);

  const companySlug = useParams().menu;
  const { hash: selectedTab } = useLocation();

  const { allMenuOptions, company, configuration, error, isCountByProduct, isError, isFetching, menu, refetch } = useBootMenu(companySlug ?? '');

  const { hasPaymentPending } = useCheckPendingPaymentProcess(companySlug, configuration);

  useEffect(() => {
    if (!companySlug || !menu || !company) return;
    if (selectedMenuOption && removeAccents(selectedTab).toLocaleLowerCase() === selectedMenuOption?.name.toLocaleLowerCase()) return;

    menuController.trackClientAccess(companySlug, company._id);

    if (
      configuration?.menuOptionsEnabled &&
      (allMenuOptions?.length && allMenuOptions?.length > 0)
    ) {
      let menuOptionFilter = allMenuOptions;

      menuOptionFilter = menuController.filterMenuOptionsByConfig(
        configuration.menuOptionsShouldBeHidden,
        menuOptionFilter
      );

      if (menuOptionFilter.length === 0) {
        // None Tab is available for the current day
        setMenuNotFound(true);
        return;
      }

      const menuOption = menuController.getSelectedMenuOption(
        menuOptionFilter,
        selectedTab
      );
      setMenuOptions(menuOptionFilter);
      setSelectedMenuOption(menuOption);

      const filteredData = menuController.doFilterMenu(
        menuOption,
        menu
      );

      setDataFiltered(filteredData);
    } else {
      setDataFiltered(menu);
    }
    setData(menu);

    if (menuController.canLogInAnalyticsFirebase()) {
      logEstablishmentEvent(company.slug);
    }
  }, [allMenuOptions, company, configuration, companySlug, menu, selectedTab, selectedMenuOption]);

  const handleChangeMenuOption = (selectedOption: FeaturesMenuOptions) => {
    setSelectedMenuOption(selectedOption);

    const filteredData = menuController.doFilterMenu(selectedOption, data);
    setDataFiltered(filteredData);
  };

  const addProductInBag = () => {
    if (!configuration?.sellViaWhatsappEnabled) return;

    updateOrderBag();
  }

  const handleItemClick = (isUpdate: boolean, product: MenuItem) => {
    if (!isUpdate) {
      product.counter = 1;
    }
    setProductUpdate(isUpdate);
    setSelectedMenuItem(product);
    setShowingProductDetail(true);
  }

  const handleRemoveOrderItem = (product: MenuItem) => {
    ProductStore.removeProduct(product.transientId);
    updateOrderBag();
  }

  const updateOrderBag = () => {
    setSelectedMenuItem(null);
    toast.success('Pedido atualizado');
  }

  const getProductsCounter = () => {
    const products: MenuItem[] = ProductStore.products;
    return products.reduce((previousValue, currentValue) => previousValue + currentValue.counter, 0);
  }

  const getProductsComplementsCounter = () => ProductStore.getComplementsCounter();

  const showNotificationPopUp = () => {
    return (
      configuration?.companyFeatures?.notificationPopUp?.isEnabled &&
      configuration?.companyFeatures.notificationPopUp.content
    );
  };

  const handleAlreadyShowPopSellsViaWhatapp = () => {
    setAlreadyShownSellsViaWhatsapp(true);
  };

  const shouldShowTitle = useMemo(() => {
    if (!configuration?.menuOptionsEnabled) {
      return true;
    }

    return dataFiltered.length > 1;
  }, [configuration, dataFiltered]);

  const canShowPopupSellsViaWhatsApp = useMemo(() => {
    // There's no SellsVia Whatsapp Enabled or
    // It was disabled by the Company
    if (
      !configuration?.sellViaWhatsappEnabled ||
      !configuration?.companyFeatures?.showInformeSellsViaWhatsapp
    ) {
      return false;
    }

    if (selectedMenuOption && configuration.menuOptionsEnabled) {
      return (
        selectedMenuOption.isSellViaWhatsappEnabled &&
        !alreadyShownSellsViaWhatsapp
      );
    } else {
      if (!alreadyShownSellsViaWhatsapp) {
        return true;
      }

      return false;
    }
  }, [configuration, selectedMenuOption, alreadyShownSellsViaWhatsapp]);

  function canRenderResumeOrder() {
    const hasSellViaWhatsappEnabled = configuration?.sellViaWhatsappEnabled;
    const hasProducts = ProductStore.products.length > 0;

    // For the case that we have Tabs available, we will check for both cases:
    // With or without Whatsapp.
    if (selectedMenuOption) {
      return hasSellViaWhatsappEnabled &&
        selectedMenuOption.isSellViaWhatsappEnabled
        ? hasProducts
        : false;
    }

    // If there's no TABs, we will check for the traditional menus, if it has WhatsApp enabled or not.
    return hasSellViaWhatsappEnabled && hasProducts;
  }

  function renderFeedbackType() {
    const customPage = configuration?.companyFeatures?.feedback.customPage;
    const url = customPage ? customPage : `/feedback/${companySlug}`;

    return customPage ? (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <FloatingFeedbackButton />
      </a>
    ) : (
      <Link to={url}>
        <FloatingFeedbackButton />
      </Link>
    );
  }

  if (isError) {
    const axiosError = error as AxiosError;
    if (axiosError.message === 'Network Error') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return <NoConnection onClose={onClose} onRetry={() => refetch()} />;
        }
      });
      return null;
    } else {
      Sentry.captureMessage('Not found menu for ' + companySlug);
      return <PageNotFound message="Nenhum cardápio/catálogo disponível neste momento" extra={<small><strong>slug: {companySlug}</strong></small>} />
    }
  }

  if (isFetching || !companySlug || !configuration) {
    return <Spinner />;
  }

  const renderMainContent = () => {
    return (
      <>
        <HeaderMetadata title={company?.fantasyName} />

        <div id="page-menuonline">
          <div className="content-menuonline">
            {showNotificationPopUp() && (
              <ModalImage
                content={configuration.companyFeatures?.notificationPopUp?.content ?? ''}
              />
            )}

            {canShowPopupSellsViaWhatsApp && (
              <InformativeOfSellsPopUp shownPopup={handleAlreadyShowPopSellsViaWhatapp} />
            )}

            <MainContainer>
              {company?.coverUrl && (
                <div style={{ position: 'relative' }}>
                  <CoverContent
                    src={company.coverUrl}
                    alt="Cover"
                    dimens={company.styles?.coverDimens}
                  />
                </div>
              )}

              <div className="menuonline-header-info">
                {company?.logoUrl && (
                  <LogoContent
                    src={company.logoUrl}
                    alt="menuonline"
                    dimens={company.styles?.logoDimens}
                  />
                )}
              </div>

              <div style={{ float: 'right' }}>
                {configuration?.companyFeatures?.openingHours !== undefined &&
                  configuration.companyFeatures?.openingHours?.length > 0 && (
                    <>
                      <SeeMoreButton
                        color={menuController.getTitleColor(company)}
                        onClick={() => setAboutDetailsOpen(true)}
                      >
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ marginRight: '2px' }}
                        />{' '}
                        Horário
                      </SeeMoreButton>
                    </>
                  )}
              </div>

              {(selectedMenuOption && menuOptions.length > 1) && (
                <TabOptions
                  menuOptions={menuOptions}
                  selectedMenuOption={selectedMenuOption}
                  labelColor={menuController.getTitleColor(company)}
                  handleChangeMenuOption={handleChangeMenuOption}
                />
              )}

              {isAboutDetailsOpen && (
                <AboutDetails
                  openingHours={configuration.companyFeatures?.openingHours ?? []}
                  dismissPage={() => setAboutDetailsOpen(false)}
                />
              )}

              {(showingProductDetail && selectedMenuItem) && (
                <OrderProductDetail
                  closePopUp={() => {
                    setSelectedMenuItem(null);
                    setShowingProductDetail(false);
                  }}
                  configuration={configuration}
                  handleProductAddition={addProductInBag}
                  isUpdate={isProductUpdate}
                  product={selectedMenuItem}
                  selectedMenuOption={selectedMenuOption}
                  slug={companySlug}
                />
              )}

              <MainContent
                configuration={configuration}
                data={dataFiltered}
                handleItemClick={handleItemClick}
                layoutType={company?.layout.type}
                selectedMenuOption={selectedMenuOption}
                shouldShowTitle={shouldShowTitle}
                subTitleColor={menuController.getSubTitleColor(company)}
                titleColor={menuController.getTitleColor(company)}
                tabsAvailable={selectedMenuOption != null && menuOptions.length > 1}
              />

              {canRenderResumeOrder() && configuration.companyFeatures && (
                <>
                  <ResumeOrder
                    companyName={configuration.companyName}
                    companyFeatures={configuration.companyFeatures}
                    slug={companySlug ?? ''}
                    handleDeleteAction={handleRemoveOrderItem}
                    handleEditAction={handleItemClick}
                  />

                  <FloatingShoppingButton
                    counter={
                      isCountByProduct
                        ? getProductsCounter()
                        : getProductsComplementsCounter()
                    }
                  />
                </>
              )}

              {configuration?.companyFeatures?.feedback.isEnabled && renderFeedbackType()}

              <PaymentLoadingModal isOpen={Boolean(hasPaymentPending)}
              >
                <h3>Aguarde o pagamento ser processado, por favor não feche a página.</h3>
              </PaymentLoadingModal>
            </MainContainer>

            <Footer
              instagram={company?.instagram}
              facebook={company?.facebook}
              whatsapp={company?.whatsapp}
              phone={company?.phoneFooter}
              website={company?.websiteFooter}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {menuNotFound ? (
        <PageNotFound message="Nenhum cardápio/catálogo disponível neste momento" extra={<small><strong>slug: {companySlug}</strong></small>} />
      ) : (
        renderMainContent()
      )}
    </div>
  );
});
