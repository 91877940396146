import React, { useEffect, useMemo, useState } from 'react';
import { ClientFare } from '../../models/DataResponse';
import { Button } from 'antd';
import formatCurrency from '../../utils/formatCurrency';
import { ModalHeadLess } from '../ModalHeadless';
import { Container, CustomTable } from './style';

interface InformativeOfClientFareProps {
  clientFareList: ClientFare[];
  closePopUp(): void;
  currency: string;
};

export const InformativeOfClientFare = ({
  clientFareList,
  closePopUp,
  currency,
}: InformativeOfClientFareProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    closePopUp();
  };

  const hasAnyDescription = useMemo(() => {
    return clientFareList.filter((item) => item.description).length > 0;
  }, [clientFareList]);

  const hasAnyPrice = useMemo(() => {
    return clientFareList.filter((item) => item.price).length > 0;
  }, [clientFareList]);

  return (
    <ModalHeadLess isOpen={open} bgModal="secondary">
      <Container>
        <CustomTable>
          <thead>
            <tr>
              {hasAnyPrice && <th>Valor</th>}
              <th>{hasAnyPrice ? 'Destino' : 'Zonas'}</th>
              {hasAnyDescription && <th>Descrição</th>}
            </tr>
          </thead>
          <tbody>
            {clientFareList.map((fare) => {
              return (
                <tr key={`${fare.destiny}_${fare.price}`}>
                  {hasAnyPrice && (
                    <td>{formatCurrency(fare.price || 0, currency)}</td>
                  )}
                  <td>{fare.destiny}</td>
                  {hasAnyDescription && <td>{fare.description}</td>}
                </tr>
              );
            })}
          </tbody>
        </CustomTable>

        <Button
          className='button-close'
          onClick={handleClose}
          type='primary'
        >
          Fechar
        </Button>
      </Container>
    </ModalHeadLess>
  );
};
