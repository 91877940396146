import styled from "styled-components";

export const Content = styled.div`
  display: inline-block;
`;

export const Title = styled.span`
  color: #333333;
  font-size: 24px;
  font-weight: bold;
`;

export const FloatingContainer = styled.div`
  float: right;
  margin-left: -70px;
`;
