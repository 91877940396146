import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 50px;
  width: 50px;
  border-radius: 10px;
  transition: height 0.4s 0.1s, width 0.4s 0.1s, box-shadow 0.3s;
  transition-timing-function: cubic-bezier(0.67, 0.17, 0.32, 0.95);
  background-color: white;
  box-shadow: 0px 4px 12px -6px black;

  div {
    padding: 0;
    display: flex;
    align-items: center;

    > img {
      margin-top: 12px;
      height: 22px;
      width: 22px;
    }
  }

  > p {
    font-size: 12px;
    font-weight: 500;
    color: black;
  }
`;
