import React, { useEffect, useState } from 'react';

import Spinner from '../../../components/LoaderSpinner';

import { formatDatetime } from '../../../utils/dateTime';
import api from '../../../services/api';

import { CustomTable } from '../../../common-styles';
import {
  Analytics,
  AnalyticsResponse,
} from '../../../models/responseData/Analytics';

type AnalyticListProps = {
  selectedCompanyId: string;
};

const AnalyticList = ({ selectedCompanyId }: AnalyticListProps) => {
  const [analytics, setAnalytics] = useState<Analytics[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setAnalytics([]);
  }, [selectedCompanyId]);

  useEffect(() => {
    let apiUrl = `api/analytics?page=${currentPage}&limit=25`;
    if (selectedCompanyId) {
      apiUrl += `&companyId=${selectedCompanyId}`;
    }

    api
      .get(apiUrl)
      .then((response) => {
        if (response.data.success) {
          const responseData: AnalyticsResponse = response.data;

          const sortedList = responseData.data.sort((a, b) =>
            b.createdAt.localeCompare(a.createdAt)
          );

          setAnalytics((prevAnalytics) => [...prevAnalytics, ...sortedList]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [selectedCompanyId, currentPage]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    });

    const element = document.querySelector('#lastInvisibleRow');
    if (element) {
      intersectionObserver.observe(element);
    }

    return () => intersectionObserver.disconnect();
  }, [isLoading]);

  function renderList() {
    return (
      <CustomTable>
        <thead>
          <tr>
            <th>Data</th>
            <th>Ação</th>
            <th>Usuário</th>
            <th>Email</th>
            <th>Empresa</th>
          </tr>
        </thead>
        <tbody>
          {analytics.map((row) => {
            return (
              <tr key={row._id}>
                <td>{formatDatetime(row.createdAt)}</td>
                <td>{row.action}</td>
                <td>{row.userName}</td>
                <td>{row.email}</td>
                <td>
                  {row.companyName ? row.companyName : 'QRcode Preferido'}
                </td>
              </tr>
            );
          })}
          <tr id="lastInvisibleRow" />
        </tbody>
      </CustomTable>
    );
  }

  return <>{isLoading ? <Spinner /> : renderList()}</>;
};

export default AnalyticList;
