import { BootClient } from '../models/DataResponse';
import { useFetch } from './useFetch';

const BOOT = 'boot';
const CACHE_TIME = 60 * 1000;
const STALE_TIME = 60 * 1000;

export const useBootMenu = (companySlug: string) => {
  const { data, ...rest } = useFetch<BootClient>({
    cacheTime: CACHE_TIME,
    staleTime: STALE_TIME,
    isPublicAPI: true,
    queryKey: BOOT,
    url: `api/boot/${companySlug}`,
  });

  const { allMenuOptions, company, configuration, menu } = data || {};

  return {
    allMenuOptions,
    company,
    configuration,
    isCountByProduct: configuration?.companyFeatures?.sellsConfiguration?.orderCountType === 'BY_PRODUCT',
    menu,
    ...rest
  };
};
