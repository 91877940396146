
import React from 'react';
import { Table, Typography } from 'antd';
import { ComplementItemOption } from '../../../../../../../../models/DataResponse';
import formatCurrency from '../../../../../../../../utils/formatCurrency';
import { PREFIX_LOCALSTORAGE } from '../../../../../../../../utils/Constants';
import { RoundTag } from '../../../../../../../../common-styles';

type ExpandedItemsProps = {
    items: ComplementItemOption[];
}

export const ExpandedComplementItems = ({ items }: ExpandedItemsProps) => {
    const columns = [
        {
            title: 'Status',
            width: 80,
            align: 'center' as 'center',
            render: (_: any, complement: ComplementItemOption) => {
                const color = complement.isEnabled ? 'green' : 'red';
                return (
                    <RoundTag color={color} key={complement._id}>
                        {complement.isEnabled ? 'Ativo' : 'Pausado'}
                    </RoundTag>
                );
            },
        },
        {
            title: 'Título',
            dataIndex: 'title',
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
        },
        {
            title: 'Preço',
            render: (_: any, complement: ComplementItemOption) => {
                let value = complement.price;
                if (!value || value === undefined) {
                    value = 0;
                }

                const formattedValue = formatCurrency(value, localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`) ?? 'BRL');
                return formattedValue;
            },
        }
    ];

    return (
        <div style={{ marginTop: '16px' }}>
            <Typography.Title level={4}>Itens</Typography.Title>

            <Table
                bordered
                dataSource={[...items]}
                columns={columns}
                pagination={false}
            />
        </div>
    );
}