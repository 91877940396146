import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Button } from 'antd';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';

import api from '../../../../../services/api';
import { Company } from '../../../../../models/Company';
import { DynamicStyles, ImageDimension } from '../../../../../models/DataResponse';
import { LayoutType } from '../../../../../models/Enum';

import { accordionStyles } from '../../../../../utils/Styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WHATSAPP_API_LINK } from '../../../../../utils/Constants';
import { formatDatetime } from '../../../../../utils/dateTime';
import { ContentHeader } from '../../../../../components/ContentHeader';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../components/Notification';
import { CompanySelection } from '../../../../../components/CompanySelection';
import { RemoveLabel } from './styles';

interface AddOrUpdateCompanyProps {
  isViewMode: boolean;
  pageDismissed(hasAction: boolean): void;
  selectedCompany?: Company;
}

export const AddOrUpdateCompany = ({ selectedCompany, pageDismissed, isViewMode }: AddOrUpdateCompanyProps) => {
  const [formData, setFormData] = useState<Company>({} as Company);
  const [styles, setStyles] = useState<DynamicStyles>({} as DynamicStyles);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isEnabled, setEnabled] = useState<boolean>(true);
  const [isCentralizer, setCentralizer] = useState<boolean>(selectedCompany?.isCentralizer ?? false);
  const [selectedLayoutType, setSelectedLayoutType] = useState<string>(LayoutType.MENU);
  const [viewMode, setViewMode] = useState<boolean>(isViewMode);
  const [logoDimension, setLogoDimension] = useState<ImageDimension>({} as ImageDimension);
  const [coverDimension, setCoverDimension] = useState<ImageDimension>({} as ImageDimension);

  const formRef = React.createRef<HTMLFormElement>();
  const classes = accordionStyles();

  useEffect(() => {
    if (selectedCompany) {
      setFormData(selectedCompany);
      setEnabled(selectedCompany.isActive);
      setSelectedLayoutType(selectedCompany.layout.type);

      if (selectedCompany.styles) {
        setStyles(selectedCompany.styles);

        if (selectedCompany.styles.coverDimens) {
          setCoverDimension(selectedCompany.styles.coverDimens);
        }
        if (selectedCompany.styles.logoDimens) {
          setLogoDimension(selectedCompany.styles.logoDimens);
        }
      }
    }
  }, [selectedCompany]);

  const closeModal = (withAction: boolean) => {
    pageDismissed(withAction);
    setLoading(false);
  };

  const handleToEditMode = async (event: FormEvent) => {
    event.preventDefault();
    setViewMode(false);
  };

  async function addOrUpdateCompany(event: FormEvent, dissmissPage: boolean) {
    event.preventDefault();

    if (!formRef?.current?.reportValidity()) {
      return;
    }

    setLoading(true);

    formData.slug = formData.menuUrl;
    formData.styles = styles;
    formData.styles.coverDimens = coverDimension;
    formData.styles.logoDimens = logoDimension;
    formData.layout = {
      type: selectedLayoutType,
    };
    formData.isCentralizer = isCentralizer;

    if (formData.whatsapp && !formData.whatsapp.includes(WHATSAPP_API_LINK)) {
      formData.whatsapp = `${WHATSAPP_API_LINK}${formData.whatsapp}`;
    }

    if (selectedCompany) {
      // UPDATE
      try {
        formData.isActive = isEnabled;

        await api.put(`api/company/${selectedCompany._id}`, formData);

        if (dissmissPage) {
          closeModal(true);
        } else {
          setLoading(false);
        }
      } catch (e) {
        NotificationDispatcher({
          message: 'Erro ao tentar atualizar empresa',
          type: NotificationType.ERROR,
        });
        setLoading(false);
      }
    } else {
      // CREATE
      try {
        await api.post('api/company', formData);
        closeModal(true);
      } catch (e) {
        NotificationDispatcher({
          message: 'Erro ao tentar cadastrar empresa',
          type: NotificationType.ERROR,
        });
        setLoading(false);
      }
    }
  }

  /* Only for Uilton - super admin */
  // async function removeCompany() {
  //   await api.delete(`api/company/${selectedCompany!!._id}`);
  //   closeModal(true);
  // }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.value === 'true' ? true : false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const companyDataFields = () => {
    return (
      <Accordion style={{ marginBottom: '16px' }} defaultExpanded={viewMode}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className={classes.iconColor}
              style={{ color: '#678f8e' }}
            />
          }
        >
          <Typography className={classes.heading} style={{ color: '#678f8e' }}>
            <strong>Dados da Empresa</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          <TextField
            id="fantasyName"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Nome fantasia"
            required
            onChange={handleInputChange}
            error={formData.fantasyName === ''}
            helperText={'campo obrigatório'}
            value={formData.fantasyName}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="companyName"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Razão social"
            onChange={handleInputChange}
            value={formData.companyName}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="responsible"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Responsável"
            onChange={handleInputChange}
            value={formData.responsible}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="email"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Email"
            onChange={handleInputChange}
            value={formData.email}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="address"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Endereço"
            onChange={handleInputChange}
            value={formData.address}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="cpfOrCnpj"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="CPF/CNPJ/NIF"
            onChange={handleInputChange}
            value={formData.cpfOrCnpj}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="phoneNumber"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Celular/Telefone"
            onChange={handleInputChange}
            value={formData.phoneNumber}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="menuUrl"
            variant="outlined"
            type="text"
            required
            disabled={viewMode}
            className="form-general-input"
            label="Menu"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              let keyUp = event.target.value.toLowerCase();
              keyUp = keyUp.replace(/\s/g, '');

              if (/^([a-z0-9-]+)$/.test(keyUp)) {
                setFormData({
                  ...formData,
                  menuUrl: keyUp,
                });
              }
            }}
            value={formData.menuUrl}
            helperText={
              'Permitido apenas letras, números e hífen. Exemplo: lanchonete-manu.'
            }
          />

          {selectedCompany && (
            <TextField
              id="slug"
              variant="outlined"
              type="text"
              className="form-general-input"
              label="Slug"
              disabled
              value={formData.slug}
            />
          )}

          <TextField
            id="logoSiteUrl"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="Logo para o site URL"
            disabled={viewMode}
            helperText="Esta logo será usada para o nosso site."
            onChange={handleInputChange}
            value={formData.logoSiteUrl}
          />

          <TextField
            id="logoUrl"
            variant="outlined"
            type="text"
            required
            className="form-general-input"
            label="Logo URL"
            disabled={viewMode}
            onChange={handleInputChange}
            value={formData.logoUrl}
          />

          <TextField
            id="coverUrl"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="Cover URL"
            onChange={handleInputChange}
            disabled={viewMode}
            value={formData.coverUrl}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  const stylesFields = () => {
    return (
      <Accordion style={{ marginBottom: '16px' }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className={classes.iconColor}
              style={{ color: '#678f8e' }}
            />
          }
        >
          <Typography className={classes.heading} style={{ color: '#678f8e' }}>
            <strong>Styles</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          {layoutSpec()}
          {titleColorsFields()}
          {coverImagesDimens()}
          {logoImagesDimens()}
        </AccordionDetails>
      </Accordion>
    );
  };

  const layoutSpec = () => {
    return (
      <>
        <FormControl component="fieldset">
          <FormLabel component="legend">Tipo de Layout</FormLabel>
          <RadioGroup
            name="selectedLayoutType"
            value={selectedLayoutType}
            onChange={(e) => {
              const value = (e.target as HTMLInputElement).value;
              setSelectedLayoutType(value);
            }}
          >
            <div>
              <FormControlLabel
                value={LayoutType.MENU}
                control={<Radio />}
                label="Cardápio"
                disabled={viewMode}
              />
              <FormControlLabel
                value={LayoutType.CATALOG}
                control={<Radio />}
                label="Catálogo"
                disabled={viewMode}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </>
    );
  };

  const titleColorsFields = () => {
    return (
      <Accordion style={{ marginBottom: '16px' }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className={classes.iconColor}
              style={{ color: '#678f8e' }}
            />
          }
        >
          <Typography className={classes.heading} style={{ color: '#678f8e' }}>
            <strong>Títulos (cores)</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          <TextField
            id="titleColor"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Cor do título"
            value={styles.titleColor}
            onChange={(e) => {
              setStyles({
                ...styles,
                titleColor: e.target.value,
              });
            }}
            helperText={
              'Ao deixar o campo vazio, será utilizado a cor padrão do menu. Para uma cor customizada, utilizar a cor em formato hexadecimal, ex: #DF0101'
            }
          />
          <TextField
            id="subTitleColor"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Cor do subtítulo"
            value={styles.subTitleColor}
            onChange={(e) => {
              setStyles({
                ...styles,
                subTitleColor: e.target.value,
              });
            }}
            helperText={
              'Ao deixar o campo vazio, será utilizado a cor do título.'
            }
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  const logoImagesDimens = () => {
    return (
      <Accordion style={{ marginBottom: '16px' }} defaultExpanded={viewMode}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className={classes.iconColor}
              style={{ color: '#678f8e' }}
            />
          }
        >
          <Typography className={classes.heading} style={{ color: '#678f8e' }}>
            <strong>Logo (dimensões)</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          <FormHelperText>
            Ao deixar tudo vazio, o sistema irá assumir os valores padrões
          </FormHelperText>

          <TextField
            id="height"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Altura"
            value={logoDimension.height}
            onChange={(e) => {
              setLogoDimension({
                ...logoDimension,
                height: e.target.value,
              });
            }}
          />

          <TextField
            id="width"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Largura"
            value={logoDimension.width}
            onChange={(e) => {
              setLogoDimension({
                ...logoDimension,
                width: e.target.value,
              });
            }}
          />

          <TextField
            id="objectFit"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Fit mode"
            value={logoDimension.objectFit}
            onChange={(e) => {
              setLogoDimension({
                ...logoDimension,
                objectFit: e.target.value,
              });
            }}
            helperText={'Valores: cover, scale-down, fill, none'}
          />

          <TextField
            id="mobileHeight"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Altura (mobile)"
            value={logoDimension.mobileHeight}
            onChange={(e) => {
              setLogoDimension({
                ...logoDimension,
                mobileHeight: e.target.value,
              });
            }}
          />

          <TextField
            id="mobileWidth"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Largura (mobile)"
            value={logoDimension.mobileWidth}
            onChange={(e) => {
              setLogoDimension({
                ...logoDimension,
                mobileWidth: e.target.value,
              });
            }}
          />

          <TextField
            id="mobileObjectFit"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Fit mode (mobile)"
            value={logoDimension.mobileObjectFit}
            onChange={(e) => {
              setLogoDimension({
                ...logoDimension,
                mobileObjectFit: e.target.value,
              });
            }}
            helperText={'Valores: cover, scale-down, fill, none'}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  const coverImagesDimens = () => {
    return (
      <Accordion style={{ marginBottom: '16px' }} defaultExpanded={viewMode}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className={classes.iconColor}
              style={{ color: '#678f8e' }}
            />
          }
        >
          <Typography className={classes.heading} style={{ color: '#678f8e' }}>
            <strong>Cover (dimensões)</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          <FormHelperText>
            Ao deixar tudo vazio, o sistema irá assumir os valores padrões
          </FormHelperText>

          <TextField
            id="height"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Altura"
            value={coverDimension.height}
            onChange={(e) => {
              setCoverDimension({
                ...coverDimension,
                height: e.target.value,
              });
            }}
          />

          <TextField
            id="width"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Largura"
            value={coverDimension.width}
            onChange={(e) => {
              setCoverDimension({
                ...coverDimension,
                width: e.target.value,
              });
            }}
          />

          <TextField
            id="objectFit"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Fit mode"
            value={coverDimension.objectFit}
            onChange={(e) => {
              setCoverDimension({
                ...coverDimension,
                objectFit: e.target.value,
              });
            }}
            helperText={'Valores: cover, scale-down, fill, none'}
          />

          <TextField
            id="mobileHeight"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Altura (mobile)"
            value={coverDimension.mobileHeight}
            onChange={(e) => {
              setCoverDimension({
                ...coverDimension,
                mobileHeight: e.target.value,
              });
            }}
          />

          <TextField
            id="mobileWidth"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Largura (mobile)"
            value={coverDimension.mobileWidth}
            onChange={(e) => {
              setCoverDimension({
                ...coverDimension,
                mobileWidth: e.target.value,
              });
            }}
          />

          <TextField
            id="mobileObjectFit"
            variant="outlined"
            type="text"
            disabled={viewMode}
            style={{ marginTop: '16px' }}
            className="form-general-input"
            label="Fit mode (mobile)"
            value={coverDimension.mobileObjectFit}
            onChange={(e) => {
              setCoverDimension({
                ...coverDimension,
                mobileObjectFit: e.target.value,
              });
            }}
            helperText={'Valores: cover, scale-down, fill, none'}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  const footerFields = () => {
    return (
      <Accordion style={{ marginBottom: '16px' }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className={classes.iconColor}
              style={{ color: '#678f8e' }}
            />
          }
        >
          <Typography className={classes.heading} style={{ color: '#678f8e' }}>
            <strong>Redes Sociais</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          <TextField
            id="whatsapp"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Whatsapp"
            onChange={handleInputChange}
            value={formData.whatsapp}
          />
          <TextField
            id="instagram"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Instagram"
            onChange={handleInputChange}
            value={formData.instagram}
          />
          <TextField
            id="facebook"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Facebook"
            onChange={handleInputChange}
            value={formData.facebook}
          />
          <TextField
            id="phoneFooter"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Telefone"
            onChange={handleInputChange}
            value={formData.phoneFooter}
          />
          <TextField
            id="websiteFooter"
            variant="outlined"
            type="text"
            disabled={viewMode}
            className="form-general-input"
            label="Site"
            onChange={handleInputChange}
            value={formData.websiteFooter}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <ContentHeader
        title={
          viewMode
            ? 'Detalhes da Empresa'
            : selectedCompany
              ? 'Atualizar Empresa'
              : 'Nova Empresa'
        }
      />

      <form ref={formRef}>
        <div className="form-general" style={{ marginTop: '16px' }}>
          {selectedCompany && (
            <FormControl component="fieldset">
              <FormLabel component="legend">Empresa Ativa?</FormLabel>
              <RadioGroup
                aria-label="isEnabled"
                name="isEnabled"
                value={isEnabled}
                onChange={handleCheckboxChange}
              >
                <div>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                    disabled={viewMode}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                    disabled={viewMode}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          )}
          <br />
          {selectedCompany && (
            <div className="form-general">
              <TextField
                id="companyId"
                variant="outlined"
                type="text"
                disabled={true}
                className="form-general-input"
                label="ID"
                value={formData._id}
              />

              <TextField
                id="accessCode"
                variant="outlined"
                type="text"
                disabled={true}
                className="form-general-input"
                label="Código de Acesso"
                value={formData.accessCode}
              />

              <TextField
                id="createdAt"
                variant="outlined"
                type="text"
                disabled={true}
                className="form-general-input"
                label="Data de criação"
                value={formatDatetime(formData.createdAt)}
              />

              {formData.updatedAt && (
                <TextField
                  id="updatedAt"
                  variant="outlined"
                  type="text"
                  disabled={true}
                  className="form-general-input"
                  label="Data de atualização"
                  value={formatDatetime(formData.updatedAt)}
                />
              )}

              <TextField
                id="observation"
                variant="outlined"
                type="text"
                disabled={viewMode}
                className="form-general-input"
                label="Observação"
                multiline={true}
                rows={3}
                value={formData.observation}
                onChange={handleInputChange}
              />
            </div>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={isCentralizer}
                onChange={(e) => setCentralizer(e.target.checked)}
                name="isCentralizer"
              />
            }
            label="Centralizador"
          />

          {!isCentralizer && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', marginBottom: '16px' }}>
              <CompanySelection
                companyId={formData.parentAssociation ?? ''}
                companyIdToBeIgnored={formData._id}
                inputLabel='Associar centralizador'
                help='Ao selecionar uma empresa, a mesma estará associada a escolhida, sendo esta aqui considerada como filha da empresa escolhida.'
                onCompanySelect={(company) => {
                  setFormData({
                    ...formData,
                    parentAssociation: company,
                  });
                }}
              />

              {formData.parentAssociation && (
                <RemoveLabel onClick={() => {
                  setFormData({
                    ...formData,
                    parentAssociation: null,
                  });
                }}>Remover associação</RemoveLabel>
              )}

            </div>
          )}

          {companyDataFields()}
          {stylesFields()}
          {footerFields()}

          {viewMode ? (
            <Button
              type="primary"
              onClick={handleToEditMode}
              loading={isLoading}
            >
              Editar
            </Button>
          ) : (
            <>
              {selectedCompany && (
                <Button
                  type="primary"
                  onClick={(event) => addOrUpdateCompany(event, false)}
                >
                  Aplicar Alterações
                </Button>
              )}

              <Button
                type="primary"
                style={{ marginTop: '16px' }}
                onClick={(event) => addOrUpdateCompany(event, true)}
              >
                <i
                  className="fa fa-check-circle"
                  aria-hidden="true"
                  style={{ marginRight: '10px' }}
                />
                Salvar
              </Button>
            </>
          )}

          <Button
            style={{ marginTop: '16px' }}
            danger
            onClick={() => closeModal(false)}
          >
            Cancelar
          </Button>

          {/* Only for Uilton - super admin */}
          {/* <Button
            style={{ marginTop: '16px' }}
            type="dashed"
            danger
            className="form-button"
            onClick={removeCompany}
          >
            Remover
          </Button> */}
        </div>
      </form>
    </>
  );
};
