import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

import api from '../../../services/api';
import {
  CommentLabel,
  Container,
  Heading,
  HintCharCounter,
  InputComment,
  ItemsGrid,
  LeaveLink,
  Main,
  ScoreContainer,
  SendButton,
  Title,
} from './styles';

interface FormData {
  comment: string;
  likedItems: string[];
  menuName: string;
  rating: number;
};

export const FeedbackPage = () => {
  const DESCRIPTION_CHARACTER_LIMIT = 500;

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [feedbackItems, setFeedbackItems] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>({} as FormData);

  const navigate = useNavigate();
  const params = useParams();
  const menuName = params.client || '';

  useEffect(() => {
    if (!menuName) return;

    api
      .get(`api/feedback/clientItems/${menuName}`)
      .then((response) => {
        const { feedbackItems } = response.data.data;
        setFeedbackItems(feedbackItems);
      })
      .catch((e) => {
        const error = e as AxiosError;

        console.error(
          'FeedbackPage',
          `It was not possible to load page, status code: ${error?.response?.status ?? 500
          }`
        );
        navigate(`/${menuName}`);
      });
  }, [menuName, navigate]);

  const ratingChanged = (newRating: number) => {
    setFormData({ ...formData, rating: newRating });
  };

  function handleSelectItem(selectedItem: string) {
    const alreadySelected = selectedItems.findIndex(
      (item) => item === selectedItem
    );
    if (alreadySelected >= 0) {
      const filtered = selectedItems.filter((item) => item !== selectedItem);
      setSelectedItems(filtered);
    } else {
      setSelectedItems([...selectedItems, selectedItem]);
    }
  }

  async function handleSubmit() {
    formData.menuName = menuName;
    formData.likedItems = selectedItems;

    try {
      const response = await api.post('api/feedback', formData);
      if (response.status) {
        toast.success('Avaliação enviada');
        navigate(`/${menuName}`);
      }
    } catch (error) {
      console.error(error);
      toast.error('É preciso que selecione o número de estrelas');
    }
  }

  return (
    <Container>
      <Main>
        <Title>Opinião</Title>
        <Heading>Avalie a sua experiência</Heading>
        <ScoreContainer className="no_highlights">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={40}
            activeColor="#ffd700"
          />
        </ScoreContainer>
        {feedbackItems.length > 0 && <Heading>O que gostou?</Heading>}
        <ItemsGrid>
          {feedbackItems.map((item) => (
            <li
              key={item}
              onClick={() => handleSelectItem(item)}
              className={selectedItems.includes(item) ? 'selected' : ''}
            >
              <span>{item}</span>
            </li>
          ))}
        </ItemsGrid>

        <CommentLabel>Deixe o seu comentário</CommentLabel>
        <InputComment
          placeholder="Comentário..."
          rows={5}
          maxLength={500}
          onChange={(e) =>
            setFormData({ ...formData, comment: e.target.value })
          }
        />
        <HintCharCounter>
          {`${formData.comment ? formData.comment.length : 0
            }/${DESCRIPTION_CHARACTER_LIMIT}`}
        </HintCharCounter>
        <SendButton onClick={handleSubmit}>
          <div>
            <span>Enviar</span>
          </div>
        </SendButton>

        <LeaveLink>
          <Link className="link" to={`/${menuName}`}>
            Sair
          </Link>
        </LeaveLink>
      </Main>
    </Container>
  );
};
