import React from 'react';
import { Container } from './style';

import feedbackImg from '../../assets/images/feedback.svg';

interface FloatingFeedbackButtonProps {
  style?: React.CSSProperties;
};

export const FloatingFeedbackButton = ({ style }: FloatingFeedbackButtonProps) => {
  return (
    <Container style={style}>
      <div>
        <img src={feedbackImg} alt="Feedback" />
      </div>
      <p>Avalie!</p>
    </Container>
  );
};
