import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';

import { ContentHeader } from '../../../components/ContentHeader';
import Spinner from '../../../components/LoaderSpinner';
import { NotificationDispatcher } from '../../../components/Notification';

import AddOrUpdateInformative from './addOrUpdateInformation';
import InformativeCard from './itemInformative';

import { MenuOption } from '../../../models/MenuOption';
import { CompanyFeatures } from '../../../models/CompanyFeatures';
import api from '../../../services/api';

import { Container, ContainerList } from './styles';
import { useLoggedUser } from '../../../hooks';

/**
 * @deprecated TO BE UPDATED
 */
const PushInformationPage = () => {
  const [isFormLoading, setFormLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<boolean>(false);
  const [companyFeatures, setCompanyFeatures] = useState<CompanyFeatures | any>(
    null
  );

  const { userLogged } = useLoggedUser();

  useEffect(() => {
    setFormLoading(true);

    api
      .get(`api/company/${userLogged.user.company}/features`)
      .then((response) => {
        setFormLoading(false);
        if (response.data.success) {
          const data = response.data.data as CompanyFeatures;
          setCompanyFeatures(data);
        } else {
          console.error('Não foi possível obter os dados.', response);
        }
      })
      .catch((error) => {
        console.log(error);
        setFormLoading(false);
      });
  }, [updateData, userLogged.user.company]);

  const handleAddOrUpdate = () => {
    setShowModal(true);
  };

  const modalDismissed = (withAction: boolean) => {
    setShowModal(false);
    setUpdateData(!updateData);
    if (withAction) {
      NotificationDispatcher({
        message: 'Atualizado com sucesso',
      });
    }
  };

  return (
    <>
      <ContentHeader title={MenuOption.BANNER_INFO} />

      {isFormLoading ? (
        <Spinner />
      ) : (
        <Container>
          <div style={{ marginBottom: '10px' }}>
            {showModal && (
              <AddOrUpdateInformative
                notificationPopUp={
                  companyFeatures ? companyFeatures.notificationPopUp : null
                }
                modalDismissed={modalDismissed}
                companyId={userLogged.user.company}
                companyFeatureId={companyFeatures ? companyFeatures._id : null}
              />
            )}

            <ContainerList>
              {companyFeatures == null && (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => handleAddOrUpdate()}
                >
                  Adicionar
                </Button>
              )}

              {companyFeatures != null && (
                <div onClick={() => handleAddOrUpdate()}>
                  <InformativeCard
                    title="Banner"
                    subtitle="Clique aqui para alterar"
                    tagColor={
                      companyFeatures.notificationPopUp?.isEnabled
                        ? 'green'
                        : 'red'
                    }
                    image={companyFeatures?.notificationPopUp?.content}
                  />
                </div>
              )}
            </ContainerList>
          </div>
        </Container>
      )}
    </>
  );
};

export default PushInformationPage;
