import React, { useState, useEffect, ChangeEvent } from 'react';
import Modal from 'react-modal';
import { Button } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import {
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';

import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../components/Notification';
import UploadForm from '../../../../components/upload/UploadForm';
import { tooltipStyles } from '../../../../utils/Styles';

import { NotificationPopup } from '../../../../models/DataResponse';
import api from '../../../../services/api';
import { firebaseStorage } from '../../../../services/firebase';
import { CompanyActivityLogService } from '../../../../services/CompanyActivityLogService';

import { Content, Title, FloatingContainer } from './styles';

interface Props {
  notificationPopUp?: NotificationPopup;
  companyId: string;
  companyFeatureId?: string;
  modalDismissed(withAction: boolean): void;
}

const AddOrUpdateInformative: React.FC<Props> = ({
  modalDismissed,
  notificationPopUp,
  companyId,
  companyFeatureId,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isUploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [isEnabled, setEnabled] = useState<boolean>(true);
  const [imageUrl, setUrl] = useState('');

  const formRef = React.createRef<HTMLFormElement>();
  const classesTooltip = tooltipStyles();

  useEffect(() => {
    setModalOpen(true);
    Modal.setAppElement('body');
    if (notificationPopUp) {
      setEnabled(notificationPopUp.isEnabled);
      setUrl(notificationPopUp.content);
    }
  }, [notificationPopUp]);

  const closeModal = (withAction: boolean) => {
    setModalOpen(false);
    modalDismissed(withAction);
    setLoading(false);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.value === 'true' ? true : false);
  };

  const handlePushChange = async () => {
    if (!formRef?.current?.reportValidity()) {
      return;
    }

    setLoading(true);

    const body = {
      notificationPopUp: {
        title: 'Banner',
        content: imageUrl,
        isEnabled,
        // "actionLabel": "siga!",
        // "actionLink": "http://dummy.com"
      },
    };

    if (notificationPopUp) {
      // Update
      try {
        const response = await api.put(
          `api/company/${companyId}/features/${companyFeatureId}`,
          body
        );
        if (response.data.success) {
          CompanyActivityLogService.register({ action: `Banner informativo atualizado`, actionArea: 'Push Informativo' });
          closeModal(true);
        }
      } catch (err) {
        NotificationDispatcher({
          message: 'Erro ao tentar efetuar operação',
          type: NotificationType.ERROR,
        });
      }
      setLoading(false);
    } else {
      // Create
      try {
        const response = await api.post(
          `api/company/${companyId}/features`,
          body
        );
        if (response.data.success) {
          CompanyActivityLogService.register({ action: `Novo Banner informativo`, actionArea: 'Push Informativo' });
          closeModal(true);
        }
      } catch (err) {
        NotificationDispatcher({
          message: 'Erro ao tentar efetuar operação',
          type: NotificationType.ERROR,
        });
      }

      setLoading(false);
    }
  };

  const removeBanner = () => {
    confirmAlert({
      title: 'Atenção',
      message:
        'Tem certeza que deseja remover este banner? Uma vez removido, esta ação não poderá ser revertida.',
      buttons: [
        {
          label: 'Sim',
          onClick: () => executeDeletion(),
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
    });
  };

  const executeDeletion = async () => {
    setLoading(true);

    // Remove image from Firebase
    if (imageUrl) {
      const ref = firebaseStorage.refFromURL(imageUrl);
      ref
        .delete()
        .then(function () {
          // File deleted successfully
          console.warn('Removed successfully');
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
          console.warn('Removed Error');
        });
    }

    // Remove from API
    try {
      const body = {
        notificationPopUp: null,
      };

      await api.put(
        `api/company/${companyId}/features/${companyFeatureId}`,
        body
      );

      CompanyActivityLogService.register({ action: `Banner informativo removido`, actionArea: 'Push Informativo' });
      closeModal(true);
    } catch (e) {
      NotificationDispatcher({
        message: 'Erro ao tentar efetuar operação',
        type: NotificationType.ERROR,
      });
      setLoading(false);
    }
  };

  return (
    <Content>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => closeModal(false)}
        className="content"
        contentLabel="Example Modal"
      >
        <div className="form-page">
          <div className="card card-container">
            <Typography>
              <Title>
                {notificationPopUp
                  ? 'Atualizar Informativo'
                  : 'Novo Informativo'}
              </Title>
            </Typography>

            <FloatingContainer>
              <Tooltip title="Cancelar">
                <Fab
                  color="primary"
                  className={classesTooltip.fab}
                  size="small"
                  onClick={() => closeModal(false)}
                >
                  <CloseIcon />
                </Fab>
              </Tooltip>
            </FloatingContainer>

            <form ref={formRef}>
              <div className="form-general" style={{ marginTop: '16px' }}>
                {notificationPopUp && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Informação Ativa?</FormLabel>
                    <RadioGroup
                      aria-label="isEnabled"
                      name="isEnabled"
                      value={isEnabled}
                      onChange={handleCheckboxChange}
                    >
                      <div>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                )}

                <UploadForm
                  setUrl={setUrl}
                  url={imageUrl}
                  setUploadInProgress={setUploadInProgress}
                  isShortImage={false}
                />
                {imageUrl ? (
                  <FormHelperText>
                    Atenção: Ao clicar no icone de excluir a imagem, a ação não
                    poderá ser desfeita.
                  </FormHelperText>
                ) : (
                  <FormHelperText>
                    Escolha uma imagem para adicionar ao informativo.
                  </FormHelperText>
                )}

                <Button
                  loading={isLoading}
                  disabled={isUploadInProgress}
                  type="primary"
                  style={{ marginTop: '16px' }}
                  onClick={() => handlePushChange()}
                >
                  <i
                    className="fa fa-check-circle"
                    aria-hidden="true"
                    style={{ marginRight: '10px' }}
                  />
                  Salvar
                </Button>

                {notificationPopUp && (
                  <Button danger onClick={() => removeBanner()}>
                    <i
                      className="fa fa-trash"
                      aria-hidden="true"
                      style={{ marginRight: '10px' }}
                    />
                    Remover
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default AddOrUpdateInformative;
