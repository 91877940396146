import React, { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import {
  ComplementOfProduct,
  MenuItem,
} from '../../../../../../models/DataResponse';
import ComplementItemOptionsSingleChoice from './ComplementItemOptionsSingleChoice';
import ComplementItemOptionsMultipleChoices from './ComplementItemOptionsMultipleChoices';

import { Container, Content, Header } from './style';
import { useState } from 'react';
import { ComplementAction } from '../../../../../../models/Enum';

export interface ComplementWrapper {
  complement: ComplementOfProduct;
  action: ComplementAction;
}

type ProductComplementsProps = {
  hasSellViaWhatsappEnabled: boolean;
  product: MenuItem;
  currency: string;
  onValueChanged(complementWrapper: ComplementWrapper): void;
};

const ProductComplements: React.FC<ProductComplementsProps> = ({
  product,
  currency,
  hasSellViaWhatsappEnabled,
  onValueChanged,
}) => {
  const [complementList, setComplementList] = useState<ComplementOfProduct[]>(
    []
  );
  const [updateList, setUpdateList] = useState(false);

  useEffect(() => {
    const complements =
      product.complements?.filter((item) => item.isEnabled) || [];
    setComplementList(complements);
  }, [product.complements, updateList]);

  function getRequiredFieldsCountLabel(count: number, complement: ComplementOfProduct) {
    if (count === 1) {
      return 'Escolha 1 opção';
    }

    if (!count) {
      // TODO: remove this after analysis.
      Sentry.captureMessage('Complements Final Client | Count value is undefined' + JSON.stringify(complement));
    }

    return `Escolha até ${count} opções`;
  }

  function shouldShowRequiredLabel(complement: ComplementOfProduct) {
    return complement.isFulfilledRequirements === undefined
      ? complement.isRequired
      : complement.isFulfilledRequirements;
  }

  function updateRequiredLabel(complement: ComplementOfProduct) {
    const index = complementList.indexOf(complement);
    complementList[index] = complement;
    setComplementList(complementList);
    setUpdateList(!updateList);
  }

  function onSelectedOptionsChanged(
    complement: ComplementOfProduct,
    updateRequiredLabel: (complement: ComplementOfProduct) => void,
    onValueChanged: (complementWrapper: ComplementWrapper) => void,
    action: ComplementAction
  ) {
    const filteredOption = complement.complementItemOptions.filter(
      (option) => option.counter !== undefined && option.counter !== 0
    );

    const isFulfilled = complement.isRequired
      ? filteredOption.length === 0
      : complement.isRequired;
    complement.isFulfilledRequirements = isFulfilled;

    const cloneComplement = {
      ...complement,
      isFulfilledRequirements: !isFulfilled,
    };

    updateRequiredLabel(complement);
    onValueChanged({
      complement: cloneComplement,
      action,
    });
  }

  if (!complementList || complementList.length <= 0) {
    return <></>;
  }

  const renderRequiredOrOptionalLabel = (complement: ComplementOfProduct) =>
    shouldShowRequiredLabel(complement) ? (
      <span className="required-label">OBRIGATÓRIO</span>
    ) : (
      <i
        className="fa fa-check"
        aria-hidden="true"
        style={{ color: '#78C129', padding: '20px' }}
      />
    );

  return (
    <Container>
      {complementList.map((complement) => {
        const options = complement.complementItemOptions.filter(
          (item) => item.isEnabled
        );

        return (
          <Content key={complement._id}>
            <Header>
              <div className="header-title">
                <small id="title">{complement.title}</small>
                <small id="description">{complement.description}</small>
                <small className="count-required-label">
                  {hasSellViaWhatsappEnabled &&
                    getRequiredFieldsCountLabel(complement.requiredFieldsCount, complement)}
                </small>
              </div>

              {hasSellViaWhatsappEnabled && renderRequiredOrOptionalLabel(complement)}
            </Header>

            {complement.isSingleChoice ? (
              <ComplementItemOptionsSingleChoice
                hasSellViaWhatsappEnabled={hasSellViaWhatsappEnabled}
                options={options}
                currency={currency}
                onSelectedOptions={(action) =>
                  onSelectedOptionsChanged(
                    complement,
                    updateRequiredLabel,
                    onValueChanged,
                    action
                  )
                }
              />
            ) : (
              <ComplementItemOptionsMultipleChoices
                hasSellViaWhatsappEnabled={hasSellViaWhatsappEnabled}
                options={options}
                currency={currency}
                maxAllowed={complement.requiredFieldsCount}
                onSelectedOptions={(action) =>
                  onSelectedOptionsChanged(
                    complement,
                    updateRequiredLabel,
                    onValueChanged,
                    action
                  )
                }
              />
            )}
          </Content>
        );
      })}
    </Container>
  );
};

export default ProductComplements;
