import React, { useEffect, useMemo, useState } from 'react';

import { Space, Table } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { ComplementItemOption } from '../../../../../../../../../models/DataResponse';
import formatCurrency from '../../../../../../../../../utils/formatCurrency';
import { PREFIX_LOCALSTORAGE } from '../../../../../../../../../utils/Constants';
import { TableEditAction } from '../../../../../../../../../components/TableEditAction';
import { TableDeleteAction } from '../../../../../../../../../components/TableDeleteAction';
import { RoundTag } from '../../../../../../../../../common-styles';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => (
  <tbody {...props} />
));
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: 'rgb(153, 153, 153)' }} />
));

interface ComplementTableProps {
  items: ComplementItemOption[];
  handleSelectedComplementOption(item: ComplementItemOption): void;
  handleDeleteComplementOption(item: ComplementItemOption): void;
  onTableChanged(items: ComplementItemOption[]): void;
}

const ComplementTable = ({
  items,
  handleDeleteComplementOption,
  handleSelectedComplementOption,
  onTableChanged,
}: ComplementTableProps) => {
  const [dataSource, setDataSource] = useState<ComplementItemOption[]>([]);

  useEffect(() => {
    setDataSource(items);
  }, [items]);

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  const hasColumnColor = () => items.filter((item) => item.color).length > 0;

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      visible: true,
      render: () => <DragHandle />,
    },
    {
      title: 'Status',
      width: 80,
      visible: true,
      align: 'center' as 'center',
      render: (_: any, complement: ComplementItemOption) => {
        const color = complement.isEnabled ? 'green' : 'red';
        return (
          <RoundTag color={color} key={complement._id}>
            {complement.isEnabled ? 'Ativo' : 'Pausado'}
          </RoundTag>
        );
      },
    },
    {
      title: 'Título',
      dataIndex: 'title',
      visible: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      visible: true,
    },
    {
      title: 'Preço',
      visible: true,
      render: (_: any, complement: ComplementItemOption) => {
        let value = complement.price;
        if (!value || value === undefined) {
          value = 0;
        }

        const formattedValue = formatCurrency(value, currency);
        return formattedValue;
      },
    },
    {
      title: 'Cor',
      visible: hasColumnColor(),
      width: 80,
      align: 'center' as 'center',
      render: (_: any, complement: ComplementItemOption) => {
        if (complement.color === undefined) return <></>;

        const color = complement.color.includes('#')
          ? complement.color
          : `#${complement.color}`;
        return <RoundTag color={color}>{color}</RoundTag>;
      },
    },
    {
      title: 'Ações',
      visible: true,
      render: (_: any, complement: ComplementItemOption) => (
        <Space direction="vertical" key={complement._id}>
          <TableEditAction
            onClick={() => handleSelectedComplementOption(complement)}
          />

          <TableDeleteAction
            onClick={() => handleDeleteComplementOption(complement)}
            title={
              <span>
                Tem certeza que deseja remover o item <strong>{complement.title}</strong>?
              </span>
            }
          />
        </Space>
      ),
    },
  ].filter((item) => item.visible);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        ([] as ComplementItemOption[]).concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      onTableChanged(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerWrapper
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      pagination={false}
      dataSource={[...dataSource]}
      columns={columns}
      rowKey="index"
      bordered
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default ComplementTable;
