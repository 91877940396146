import React from 'react';
import parse from 'html-react-parser';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { FeaturesMenuOptions } from '../../models/DataResponse';

import { WeekdaysList } from '../../utils/Constants';
import { Popconfirm } from 'antd';

interface MenuOptionsListProps {
  deleteAction: any;
  editAction: any;
  menuOptions: FeaturesMenuOptions[];
}

const MenuOptionsList = ({ deleteAction, editAction, menuOptions, }: MenuOptionsListProps) => {

  const handleEditAction = (option: FeaturesMenuOptions) => {
    editAction(option);
  };

  const getWeekdays = (weekDays: string) => {
    if (!weekDays || weekDays === 'everyday') {
      return 'Todos os dias';
    }

    return weekDays
      .split('|')
      .filter((day) => !isNaN(Number(day)))
      .map((day) => WeekdaysList[Number(day)])
      .join(', ');
  };

  const getHours = (option: FeaturesMenuOptions) => {
    return `${option.startHour} às ${option.endHour}`;
  };

  const getSecondaryData = (option: FeaturesMenuOptions) => {
    return `<ul>
              <li>Disponibilidade: ${option.isEnabled ? 'Ativo' : 'Pausado'
      }</li>
              <li>Ordem: ${option.order ?? ''}</li>
              ${option.allDay
        ? '<li>Dia inteiro: Sim</li>'
        : `<li>Horário: ${getHours(option)}</li>`
      }
              <li>Dias: ${getWeekdays(option.weekdays)}</li>
              <li>Vendas: ${option.isSellViaWhatsappEnabled ? 'Ativo' : 'Pausado'
      }</li>
            </ul>`;
  };

  const getPrimaryData = (name: string) => {
    return `<strong><h3 style="text-align: left;">${name}</h3></strong>`;
  };

  return (
    <List>
      {menuOptions.map((option) => {
        return (
          <ListItem key={option._id}>
            <ListItemText
              id={option._id}
              primary={parse(getPrimaryData(option.name))}
              secondary={parse(getSecondaryData(option))}
            />
            <ListItemSecondaryAction>
              <EditIcon
                onClick={() => handleEditAction(option)}
                style={{ cursor: 'pointer' }}
              />
              <Popconfirm
                placement="bottomRight"
                title={`Tem certeza que deseja excluir o menu ${option.name}?`}
                onConfirm={() => deleteAction(option._id)}
                okText="Sim"
                cancelText="Não"
              >
                <DeleteIcon
                  onClick={() => { }}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                />
              </Popconfirm>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default MenuOptionsList;
