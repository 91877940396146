import React, { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Form, Input, Switch } from 'antd';
import { ComplementItemOption } from '../../../../../../../../../models/DataResponse';
import { PREFIX_LOCALSTORAGE } from '../../../../../../../../../utils/Constants';
import { FormInputField } from '../../../../../../../../../components/FormInputField';


type ComplementItemProps = {
  complementItemOption?: ComplementItemOption;
  isVisible: boolean;
  handleItem(values: any): void;
  onClose(): void;
};

const DESCRIPTION_CHARACTER_LIMIT = 100;
const TITLE_CHARACTER_LIMIT = 45;

const ComplementItem: React.FC<ComplementItemProps> = ({
  complementItemOption,
  isVisible,
  handleItem,
  onClose,
}) => {
  const [color, setColor] = useState('');

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  useEffect(() => {
    if (
      complementItemOption?.color !== undefined &&
      complementItemOption?.color !== ''
    ) {
      setColor(complementItemOption!!.color);
    }
  }, [complementItemOption]);

  const onFinish = (values: any) => {
    setColor('');
    handleItem(values);
  };

  return (
    <Drawer
      title={
        complementItemOption === undefined ? 'Novo item' : 'Atualizar item'
      }
      onClose={onClose}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isVisible}
    >
      <Form
        layout="vertical"
        initialValues={complementItemOption}
        onFinish={onFinish}
      >
        <Form.Item
          label={<label className="label-input-field">Status</label>}
          name="isEnabled"
          valuePropName="isEnabled"
        >
          <Switch
            id="isEnabled"
            key="isEnabled"
            defaultChecked={
              complementItemOption === undefined
                ? true
                : complementItemOption.isEnabled
            }
            checkedChildren="Ativo"
            unCheckedChildren="Pausado"
          />
        </Form.Item>

        <FormInputField
          label="Título"
          name="title"
          placeholder="Título"
          maxLength={TITLE_CHARACTER_LIMIT}
          rules={[
            {
              required: true,
              message: 'Título obrigatório',
            },
          ]}
        />

        <FormInputField
          label="Descrição"
          name="description"
          placeholder="Descrição"
          maxLength={DESCRIPTION_CHARACTER_LIMIT}
          isInputArea={true}
          inputTextAreaProps={{ showCount: true }}
        />

        <FormInputField
          label="Preço"
          name="price"
          prefix={currency}
          placeholder="Preço"
          type="number"
        />

        <Form.Item
          label={
            <label className="label-input-field">Cor (em Hexadecimal)</label>
          }
          name="color"
        >
          <div style={{ display: 'flex' }}>
            <Input
              id="color"
              name="color"
              addonBefore="#"
              placeholder="Ex: #ee0000"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              style={{ width: '150px', height: '40px' }}
            />
            <div
              style={{
                width: '30px',
                height: '32px',
                marginLeft: '4px',
                backgroundColor: `#${color}`,
              }}
            />
          </div>
        </Form.Item>

        <Button type="primary" htmlType="submit">
          <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />{' '}
          Salvar
        </Button>
      </Form>
    </Drawer>
  );
};

export default ComplementItem;
