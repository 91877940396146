import React, { useState } from 'react';
import { Button } from 'antd';

import { ActionType } from '../../../../../../models/Enum';
import { ClientFare } from '../../../../../../models/DataResponse';
import { CompanyFeatures } from '../../../../../../models/CompanyFeatures';

import ClientFareDetails from './ClientFareDetails';
import FareInclusionList from '../../../../../../components/FareList';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../../components/Notification';
import { EmptyStateMessage } from '../../../styles';
import { UpdateCompanyFeaturesService } from '../../../../../../services/UpdateCompanyFeaturesService';

type ClientFarePageProps = {
  companyId: string;
  currency: string;
  companyFeatures: CompanyFeatures;
};

export const ClientFarePage = ({
  companyId,
  currency,
  companyFeatures,
}: ClientFarePageProps) => {
  const [isPageOpen, setPageOpen] = useState(false);
  const [selectedClientFare, setSelectedClientFare] = useState<ClientFare>();
  const [clientFareList, setClientFareList] = useState<ClientFare[]>(
    companyFeatures.sellsConfiguration!!.clientFare || []
  );

  function handleClientFareEditAction(clientFare: ClientFare) {
    setSelectedClientFare(clientFare);
    setPageOpen(true);
  }

  const handleClientFareDeletion = async (clientFare: ClientFare) => {
    await UpdateCompanyFeaturesService.deleteDeliveryFee(clientFare._id, companyId);

    const filtered = clientFareList.filter((item) => item._id !== clientFare._id);
    setClientFareList(filtered);
  }

  const onClosePage = (actiontype: ActionType, clientFare?: ClientFare) => {
    switch (actiontype) {
      case ActionType.ADD:
        setClientFareList([...clientFareList, clientFare!]);
        break;
      case ActionType.UPDATE:
        const index = clientFareList.findIndex(
          (item) => item._id === clientFare?._id
        );
        if (index !== -1) {
          clientFareList[index] = clientFare!;
        } else {
          NotificationDispatcher({
            message: 'Erro ao tentar atualizar Frete/Zona.',
            type: NotificationType.ERROR,
          });
        }
        break;
    }
    setPageOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setSelectedClientFare(undefined);
          setPageOpen(true);
        }}
      >
        <i
          className="fa fa-plus-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Adicionar Frete
      </Button>

      <ClientFareDetails
        isVisible={isPageOpen}
        companyFeatureId={companyFeatures._id}
        companyId={companyId}
        selectedClientFare={selectedClientFare}
        currency={currency}
        onClose={onClosePage}
      />

      {clientFareList.length > 0 ? (
        <FareInclusionList
          clientFareList={clientFareList}
          deleteAction={handleClientFareDeletion}
          editAction={handleClientFareEditAction}
          currency={currency}
        />
      ) : (
        <EmptyStateMessage>
          <strong>Nenhum frete cadastrado</strong>
        </EmptyStateMessage>
      )}
    </>
  );
};
