import React, { useMemo, useState } from 'react';
import LazyLoad from 'react-lazyload';
import parse from 'html-react-parser';
import { ModalImage } from '../ModalImage';

import {
  FeaturesMenuOptions,
  MenuConfiguration,
  MenuItem,
} from '../../models/DataResponse';
import { isEmptyObject } from '../../utils/Util';
import { sanitizePrice } from '../../utils/priceUtil';
import formatCurrency from '../../utils/formatCurrency';

import {
  DetailsButton,
  Container,
  PriceDiscountContent,
  ProductDescription,
  ProductImage,
  ProductPrice,
  ProductTitle,
} from './styles';
import { PriceWithPromotion } from '../PriceWithPromotion';

enum Buttons {
  ADD = 'Adicionar',
  DETAILS = 'Detalhes',
}

interface CardInfoProps {
  data: MenuItem;
  hasSellViaWhatsappEnabled: boolean;
  selectedMenuOption: FeaturesMenuOptions | null;
  configuration: MenuConfiguration;
  handleAddClick?(): void;
};

export const CardInfo = ({
  data,
  hasSellViaWhatsappEnabled,
  selectedMenuOption,
  configuration,
  handleAddClick,
}: CardInfoProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(Buttons.DETAILS);

  const { name, description, imageUrl, price, promotion, complements } = data;
  const hasComplements = complements && complements.length > 0;

  const hasImage = useMemo(() => {
    return (imageUrl && imageUrl !== '' && imageUrl !== undefined) || false;
  }, [imageUrl]);

  const canShowButton = useMemo(() => {
    // For the case that we have Tabs available, we will check for both cases:
    // With or without Whatsapp.
    if (selectedMenuOption) {
      if (hasSellViaWhatsappEnabled && selectedMenuOption.isSellViaWhatsappEnabled) {
        setButtonLabel(Buttons.ADD);
        return selectedMenuOption.isSellViaWhatsappEnabled;
      } else {
        setButtonLabel(Buttons.DETAILS);
        return hasComplements;
      }
    }

    // If there's no TABs, we will check for the traditional menus, if it has WhatsApp enabled or not.
    if (hasSellViaWhatsappEnabled) {
      setButtonLabel(Buttons.ADD);
      return (
        !selectedMenuOption ||
        (selectedMenuOption as FeaturesMenuOptions).isSellViaWhatsappEnabled
      );
    }

    return hasComplements;
  }, [hasSellViaWhatsappEnabled, hasComplements, selectedMenuOption]);

  const expandImage = () => {
    setIsOpen(!isOpen);
  };

  const getPromotionWithDiscount = () => {
    if (!promotion) return null;

    return (
      <PriceWithPromotion
        currency={configuration.companyFeatures?.sellsConfiguration?.currency ?? 'BRL'} // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52}
        discountPercent={promotion.discountPercent}
        newPrice={promotion.newPrice}
        oldPrice={promotion.oldPrice}
      />
    );
  };

  const getPromotionWithDiffValues = () => {
    return (
      <PriceDiscountContent hasImage={hasImage}>
        <h4>{getFormattedPrice(promotion?.oldPrice)}</h4>
        <h3>{getFormattedPrice(promotion?.newPrice)}</h3>
      </PriceDiscountContent>
    );
  };

  const getFormattedPrice = (value?: string) => {
    if (
      !value ||
      value === undefined ||
      isNaN(Number(sanitizePrice(value, false)))
    ) {
      return value;
    }

    const price = Number(sanitizePrice(value));
    return formatCurrency(
      price,
      configuration.companyFeatures?.sellsConfiguration?.currency ?? 'BRL' // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    );
  }

  const getNormalPrice = (value: string | undefined) => {
    return <p>{getFormattedPrice(value)}</p>;
  };

  const hasPromotion = () => {
    return (
      promotion &&
      !isEmptyObject(promotion) &&
      promotion.oldPrice &&
      promotion.newPrice
    );
  };

  const renderPrice = () => {
    return hasPromotion()
      ? promotion?.discountPercent
        ? getPromotionWithDiscount()
        : getPromotionWithDiffValues()
      : getNormalPrice(price);
  };

  return (
    <Container>
      <ProductTitle>
        <span>{name && parse(name)}</span>
      </ProductTitle>

      {description && (
        <ProductDescription>
          <span>{parse(description)}</span>
        </ProductDescription>
      )}

      <ProductPrice>
        {renderPrice()}
      </ProductPrice>

      {imageUrl && (
        <ProductImage>
          <div onClick={expandImage}>
            <LazyLoad height={120} once={true}>
              <img
                className="grid-item-picture-specs"
                src={imageUrl}
                alt={name}
              />
            </LazyLoad>
          </div>
        </ProductImage>
      )}

      {canShowButton && (
        <DetailsButton
          isDetail={buttonLabel === Buttons.DETAILS}
          color="black"
          onClick={handleAddClick}
        >
          {buttonLabel}
        </DetailsButton>
      )}

      {isOpen && <ModalImage content={imageUrl ?? ''} />}
    </Container>
  );
};
