import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { confirmAlert } from 'react-confirm-alert';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { ClientFare } from '../../models/DataResponse';
import formatCurrency from '../../utils/formatCurrency';
import { RoundTag } from '../../common-styles';

type FareListProps = {
  clientFareList: ClientFare[];
  deleteAction(clientFare: ClientFare): void;
  editAction(clientFare: ClientFare): void;
  currency: string;
};

const FareInclusionList: React.FC<FareListProps> = ({
  clientFareList,
  deleteAction,
  editAction,
  currency,
}) => {
  function handleDeleteAction(fare: ClientFare) {
    confirmAlert({
      title: 'Atenção',
      message: `Tem certeza que deseja excluir o frete ${fare.destiny}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => deleteAction(fare),
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
    });
  }

  function handleEditAction(clientFare: ClientFare) {
    editAction(clientFare);
  }

  return (
    <List>
      {clientFareList.map((option, index) => {
        return (
          <ListItem
            key={option._id}
            style={{ background: index % 2 === 0 ? '#f2f2f2' : '' }}
          >
            <ListItemText
              id={option._id}
              primary={option.destiny}
              secondary={formatCurrency(option.price || 0, currency)}
            />
            <ListItemSecondaryAction style={{ display: 'flex' }}>
              <RoundTag
                color={option.isEnabled ? 'green' : 'red'}
                style={{ marginTop: '3px', marginRight: '12px' }}
              >
                {option.isEnabled ? 'Ativo' : 'Pausado'}
              </RoundTag>

              <EditIcon
                onClick={() => handleEditAction(option)}
                style={{ cursor: 'pointer' }}
              />
              <DeleteIcon
                onClick={() => handleDeleteAction(option)}
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default FareInclusionList;
