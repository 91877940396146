import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Footer } from '../../../components/Footer';
import Spinner from '../../../components/LoaderSpinner';
import { ModalImage } from '../../../components/ModalImage';

import MenuController from '../MenuOnline/MenuController';

import { logEstablishmentEvent } from '../../../services/firebase';

import {
  FeaturesMenuOptions,
  MenuItem,
} from '../../../models/DataResponse';

import { FeedbackButton } from './components/FeedbackButton';
import { useRodeadouro } from './hooks/useRodeadouro';
import {
  MenuOptionsContainer,
  MenuOptionsItemsContainer,
} from '../MenuOnline/styles';
import './styles.css';

const menuController = new MenuController();

export const Rodeadouro = () => {
  const [selectedMenuOption, setSelectedMenuOption] = useState<FeaturesMenuOptions>({} as FeaturesMenuOptions);
  const [menuOptions, setMenuOptions] = useState<FeaturesMenuOptions[]>([]);
  const [selectedData, setSelectedData] = useState<MenuItem>({} as MenuItem);
  const { data, company, configuration, isLoading } = useRodeadouro();

  useEffect(() => {
    if (!data) return;

    logEstablishmentEvent('Rodeadouro');

    menuController.trackClientAccess(
      'rodeadouro',
      '5f6b2ebeee0271001721ae39'
    );

    const menuOptionFilter = data.allMenuOptions.sort(
      (a, b) => a.order - b.order
    );
    const selectedMenuOption =
      menuController.getSelectedMenuOption(menuOptionFilter);

    setMenuOptions(data.allMenuOptions);
    setSelectedMenuOption(selectedMenuOption);

    const filteredData = menuController.doFilterMenu(
      selectedMenuOption,
      data.menu,
    );
    setSelectedData(filteredData[0].items[0]);
  }, [data]);

  const handleChangeMenuOption = (selectedOption: FeaturesMenuOptions) => {
    setSelectedMenuOption(selectedOption);

    const filteredData = menuController.doFilterMenu(selectedOption, data?.menu ?? []);
    setSelectedData(filteredData[0].items[0]);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const showNotificationPopUp = () => {
    return (
      configuration?.companyFeatures?.notificationPopUp?.isEnabled &&
      configuration?.companyFeatures.notificationPopUp.content
    );
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div id="page-rodeadouro">
      {showNotificationPopUp() && (
        <ModalImage
          content={configuration?.companyFeatures?.notificationPopUp?.content ?? ''}
        />
      )}

      <div className="content">
        <main className="main-rodeadouro">
          <img
            className="rodeadouro-menu-image"
            src={selectedData.name}
            alt="Rodeadouro Menu"
          />
        </main>

        <MenuOptionsContainer style={{ marginBottom: '16px' }}>
          <MenuOptionsItemsContainer>
            {menuOptions.map((option) => {
              return (
                <i
                  key={option._id}
                  className={option.icon}
                  onClick={() => handleChangeMenuOption(option)}
                  style={{
                    color:
                      option === selectedMenuOption ? '#F0D89A' : '#FFFFFF',
                    cursor: 'pointer',
                  }}
                />
              );
            })}
          </MenuOptionsItemsContainer>

        </MenuOptionsContainer>

        <div className='feedback-item'>
          <Link to={'/feedback/rodeadouro'}>
            <FeedbackButton />
          </Link>
        </div>

        <Footer
          instagram={company?.instagram}
          facebook={company?.facebook}
          whatsapp={company?.whatsapp}
          phone={company?.phoneFooter}
        />
      </div>
    </div>
  );
};
