import { Coupon } from "../models/Coupon";
import Big from 'big.js';
import { ClientFare, ComplementItemOption, MenuItem } from "../models/DataResponse";
import { ExtraFee } from "../models/SellsConfiguration";
import formatCurrency from "../utils/formatCurrency";
import { sanitizePrice } from "../utils/priceUtil";

export interface UseProductCostProps {
  coupon?: Coupon;
  currency: string;
  deliveryFee?: ClientFare;
  extraFees?: ExtraFee[]
  products: MenuItem[];
}

const calculateProductsTotalCost = (products: MenuItem[]): number => {
  let amount = 0;

  products.forEach((product) => {
    let computedOptions = [] as ComplementItemOption[];
    let complementAmount = 0;

    product.complements?.forEach((complement) =>
      complement.complementItemOptions.forEach((option) =>
        computedOptions.push(option)
      )
    );

    computedOptions
      .filter(
        (product) => product.counter !== undefined && product.counter !== 0
      )
      .forEach(
        (option) => (complementAmount += option.price * option.counter)
      );

    let productPrice = product.price;

    if (
      !productPrice ||
      productPrice === undefined ||
      isNaN(Number(sanitizePrice(productPrice)))
    ) {
      productPrice = '0';
    }

    const unitPrice = Number(sanitizePrice(productPrice)) + complementAmount;
    amount += unitPrice * product.counter;
  });

  return amount;
}

export const useProductCost = ({ coupon, currency, deliveryFee, extraFees, products }: UseProductCostProps) => {

  const subtotalBig = new Big(calculateProductsTotalCost(products));
  let totalValueBig = subtotalBig;

  if (coupon) {
    const isPercentage = Number(coupon.discountType) === 0;
    if (isPercentage) {
      const discount = totalValueBig.times(coupon.discountValue).div(100);
        totalValueBig = totalValueBig.minus(discount);
    } else {
      totalValueBig = totalValueBig.minus(coupon.discountValue);
    }
  }

  if (deliveryFee && deliveryFee.price) {
    totalValueBig = totalValueBig.plus(deliveryFee.price);
  }

  if (extraFees) {
    const fees = extraFees.filter(item => item.isEnabled)
        .reduce((prevAmount, currentValue) =>
          new Big(prevAmount).plus(currentValue.value),
          new Big(0));
      totalValueBig = totalValueBig.plus(fees);
  }

  const subtotal = parseFloat(subtotalBig.toString());
  const total = parseFloat(totalValueBig.toString());
  return {
    subtotal,
    subtotalFormatted: formatCurrency(subtotal, currency),
    totalCost: total,
    totalCostFormatted: formatCurrency(total, currency),
  };
}