import { useCallback, useState } from "react";
import * as Sentry from '@sentry/browser';
import { confirmAlert } from "react-confirm-alert";
import { useBootMenu } from "../../../../hooks";
import { Order } from "../../../../models/DataResponse";
import MenuController from "../MenuController";
import { ProductStore } from '../../../../hooks/ProductStore/productsStore';
import { sendWhatsAppMessage } from "../../../../utils/SendWhatsappMessageUtil";

export const useOrderRequest = (slug: string) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { company, configuration } = useBootMenu(slug);

  const showErrorMessageOrderNotSent = () => {
    confirmAlert({
      title: 'Atenção',
      message:
        'Não foi possível enviar o seu pedido para o servidor. Por favor, tente novamente.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    });
  }

  const showDialogInformingOrderWasSent = useCallback((callback: () => any) => {
    confirmAlert({
      title: 'Pronto!',
      message: configuration?.companyFeatures?.informingOrderWasSentToWhatsappMessage,
      buttons: [
        {
          label: 'Ok',
          onClick: () => callback(),
        },
      ],
      closeOnClickOutside: false,
      onKeypressEscape: () => callback(),
    });
  }, [configuration]);

  const executeOrderRequest = useCallback(async (order: Order) => {
    if (!configuration) return;

    setIsLoading(true);

    const message = await new MenuController().sendOrder({
      companyName: company?.fantasyName ?? '',
      companySlug: slug,
      menuConfiguration: configuration,
      order,
    });

    if (!message) {
      Sentry.captureMessage('[Non-Fatal] Order not sent to the Server');
      showErrorMessageOrderNotSent();
      setIsLoading(false);
      return;
    }

    showDialogInformingOrderWasSent(() => {
      sendWhatsAppMessage(message, configuration.whatsappNumber);

      setIsLoading(false);
      ProductStore.clearProducts();
    });
  }, [company, configuration, showDialogInformingOrderWasSent, slug]);

  return { menuConfiguration: configuration, isLoading, executeOrderRequest };
}