import React, { useMemo } from "react";
import { Divider } from "antd";
import { CompanyFeatures } from "../../../../../../models/CompanyFeatures";
import { FormSwitchItem } from "../../../../../../components/FormSwitchItem";
import { ClientFarePage } from "../../../SettingsStore/components/ClientFare";
import { PREFIX_LOCALSTORAGE } from "../../../../../../utils/Constants";

interface DeliveryFeesProps {
  companyId: string;
  companyFeatures: CompanyFeatures;
  handleCheckboxChange(checkedValue: boolean, switchId: string): void;
}

export const DeliveryFees = ({ companyId, companyFeatures, handleCheckboxChange }: DeliveryFeesProps) => {

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  return (
    <>
      <FormSwitchItem
        label="O seu Frete/Zona é obrigatório?"
        propertyId="isFareRequired"
        defaultChecked={
          companyFeatures.sellsConfiguration?.isFareRequired ?? false
        }
        handleCheckboxChange={handleCheckboxChange}
      />

      <Divider />

      <ClientFarePage
        currency={currency}
        companyId={companyId}
        companyFeatures={companyFeatures}
      />
    </>
  );
}